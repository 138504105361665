export const SortableOptions = {
  animation: 0,
  fallbackOnBody: true,
  swapThreshold: 0.65,
  ghostClass: 'ghost',
  group: {
    name: 'orphan-child',
    put: ['orphan-child', 'group-collection'],
    pull: true,
  },
};
