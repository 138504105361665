export interface ResolvePayload<T> {
  response: T | null;
  error: Error | null;
}

export const resolvePromise = async <T>(promise: Promise<T>) => {
  const resolved: ResolvePayload<T> = {
    response: null,
    error: null,
  };

  try {
    resolved.response = await promise;
  } catch (e) {
    resolved.error = e as unknown as Error;
  }

  return resolved;
};
