import Resizer from 'react-image-file-resizer';
import Tiff from 'tiff.js/tiff';

import { Asset } from 'base/models/Asset';

type AddPreviewType = (assetId: string, base64Small: string, base64Large: string) => void;

export const dataURLtoFile = (
  dataFile: string,
  dataType: string,
  filename: string,
  isTiffImage: boolean
) => {
  let mime = '';
  let bstr = '';
  if (isTiffImage) {
    const arr = dataFile.split(',');
    mime = 'image/png';
    bstr = atob(arr[1]);
  } else {
    mime = dataType;
    bstr = atob(dataFile);
  }
  let num = bstr.length;
  const u8arr = new Uint8Array(num);

  while (num--) {
    u8arr[num] = bstr.charCodeAt(num);
  }

  return new File([u8arr], filename, { type: mime });
};

export const validateTiffFileAndResize = (
  asset: Asset,
  assetFile: File,
  addPreview: AddPreviewType
) => {
  if (assetFile.size === 0) {
    return;
  }

  const isTifImage = assetFile.type === 'image/tif' || assetFile.type === 'image/tiff';

  if (!isTifImage) {
    resizeFileCall(asset, assetFile, addPreview);
    return;
  }

  assetFile.arrayBuffer().then((arrayBuffer) => {
    Tiff.initialize({ TOTAL_MEMORY: 16777216 * 10 });
    const tiff = new Tiff({ buffer: arrayBuffer });
    const url = tiff.toDataURL();

    assetFile = dataURLtoFile(url, assetFile.name, '', isTifImage);
    resizeFileCall(asset, assetFile, addPreview);
  });
};

const resizeFile = (file: File, height: number, width: number) =>
  new Promise<string>((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri.toString());
      },
      'base64'
    );
  });

const resizeFileCall = (asset: Asset, assetFile: File, addPreview: AddPreviewType) => {
  const resizeFileSmall = resizeFile(assetFile, 208, 284);
  const resizeFileLarge = resizeFile(assetFile, 800, 800);

  Promise.all([resizeFileSmall, resizeFileLarge])
    .then(([base64Small, base64Large]) => {
      addPreview(asset.assetId, base64Small, base64Large);
    })
    .catch(() => {
      // eslint-disable-next-line
      console.log(`Asset ${asset.assetId} could not be resized`);
    });
};
