import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'base/constants/route';

export default function Breadcrumb() {
  const [, location] = useLocation().pathname.split('/');
  const breadcrumbTitle = location === ROUTES.UPLOAD ? 'Upload' : 'Edit';

  return (
    <div>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="large" />} aria-label="breadcrumb">
        <Link color="inherit" href="/">
          <Typography variant="h5" color="textSecondary">
            <strong>Dashboard</strong>
          </Typography>
        </Link>
        <Typography variant="h5" color="primary">
          <strong>{breadcrumbTitle}</strong>
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
