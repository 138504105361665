import api from './config';
import { resolvePromise } from '../utils/resolveAPICall';
import {
  Asset,
  Assets,
  DigitalScreenerComponentList,
  ListResponse,
  SortGroup,
  SortGroupRequest,
} from 'base/models/Asset';
import { previewToken } from 'base/utils/tokenHelper';
import { SIZES } from 'base/constants/previewFiles';
import { Title } from 'base/types/title';
import { DraftAssetPatchPayload } from 'base/models/Asset';

export const putAsset = async (asset: Asset) => {
  const response = await resolvePromise(api.put<Asset>(`assets`, asset));
  return response;
};
export const putAssets = async (payload: DraftAssetPatchPayload) => {
  const response = await resolvePromise(api.put<Assets>(`assets`, payload));
  return response;
};
export const postAssetDeleteRequest = async (assetIds: string[]) => {
  const response = await resolvePromise(api.post<string[]>('requests/delete', assetIds));
  return response;
};

export const getAssetGroupings = async (
  cpmProductId: number | undefined,
  groupId: string,
  titleId: string,
  imageSize: string = SIZES.SMALL
) => {
  const response = await resolvePromise(
    api.get(
      `assets/groupings?cpmProductId=${cpmProductId}&assetGroupId=${groupId}&imageSize=${imageSize}&titleId=${titleId}`,
      {
        headers: {
          cdnt: previewToken(),
        },
      }
    )
  );
  return response.response?.data as ListResponse<SortGroup>;
};

export const saveAssetGroupings = async (items: SortGroup[], titleId: string) => {
  const sortGroupItem = prepareForSaveApiHelperMapper(items);
  const request = { assetGroupingList: sortGroupItem, titleId: titleId } as SortGroupRequest;

  const response = await resolvePromise(api.post<SortGroup[]>(`assets/groupings`, request));
  return { ...response.response, request };
};

const prepareForSaveApiHelperMapper = (items: SortGroup[]) => {
  const betweenGroupGap = 100;
  const inGroupGap = 10;
  let sortOrderIdx = 0;
  let inGroupIdx = sortOrderIdx;
  return items.map((item) => {
    sortOrderIdx = inGroupIdx + betweenGroupGap;

    return {
      ...item,
      sortOrder: sortOrderIdx,
      assets: item.assets.map((asset, idx) => {
        inGroupIdx = sortOrderIdx + inGroupGap * idx;
        const newAsset = {
          ...asset,
          isMain: idx === 0,
          sortOrder: inGroupIdx,
        };

        return newAsset;
      }),
      name: item.assets?.length > 1 ? item.name : null,
      id: null,
    };
  }) as unknown as SortGroup[];
};

export const assetCopy = async (request: { assets: Asset[]; titles: Title[] }) => {
  const response = await resolvePromise(
    api.post<{
      AssetIds: string[];
      Titles: {
        Id: string;
        VersionId: string;
        CpmProductId: string;
        WprId: string;
        PrimaryName: string;
        SecondaryName: string;
        ProductType: number;
      }[];
    }>('assets/copy', {
      AssetIds: request.assets.map((a) => a.id),
      Titles: request.titles.map((t) => ({
        Id: t.id,
        WprId: t.identifiers.wprId,
        CpmProductId: t.identifiers.productId,
        ProductType: t.productType,
        PrimaryName: t.name.primary,
        SecondaryName: t.name.secondary,
      })),
    })
  );
  return response;
};

export const GetAssetsByPackageId = async (packageId: string) => {
  const response = await resolvePromise(
    api.get<DigitalScreenerComponentList>(`assets/package?packageId=${packageId}`)
  );
  return response;
};
