import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    logo: {
      width: 203,
      marginTop: 8,
    },
    headerTitle: {
      color: '#fff',
      marginLeft: 10,
      fontSize: 24,
    },
    dashboardAppBar: {
      height: '106px',
      zIndex: 1,
      boxShadow: 'none',
    },
  })
);
