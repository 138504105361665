import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#fff',
  },
  assetProgressContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '83fr',
    gridAutoColumns: '17fr',
    columnGap: '10px',
    gridAutoFlow: 'column',
  },
  uploadButtons: {
    width: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
  },
  listItemIcon: {
    paddingTop: '5px',
    marginRight: '10px',
    fontSize: '14px',
  },
  iconButton: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
});

export const DivBreakWordContainer = styled.div`
  padding-top: 5px;
  margin-right: 10px;

  color: #444a55;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;

  overflow-wrap: anywhere;
`;

export const UploadInfoContainer = styled.div`
  color: #7e899e;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;

  margin-top: 5px;
  display: flex;
  align-items: center;
`;

export const MainContainer = styled.div`
  @media (width: 1024px) {
    width: 280px;
  }

  @media (width: 961px) {
    width: 180px;
  }

  @media (width: 769px) {
    width: 150px;
  }

  @media (width: 481px) {
    width: 100px;
  }
`;
