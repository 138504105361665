import React, { Fragment, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import BaseFieldProps from 'base/models/BaseFieldProps';
import { observer } from 'mobx-react-lite';

export interface InputTextProps extends BaseFieldProps {
  errorMessage?: string;
  isMultiline?: boolean;
}

const InputText: React.FC<InputTextProps> = ({
  label,
  errorMessage,
  isRequired,
  isDisabled,
  isVisible,
  isMultiline,
  fieldName,
  dmdcTargetField,
  value,
  onChange,
  onBlur,
}: InputTextProps) => {
  const [text, setText] = React.useState<string>(value ?? '');
  const [error, setError] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    if (onChange) {
      onChange(fieldName, event.target.value);
    }
  };
  const handleValidateField = () => {
    const isRequiredAndNotEmpty = !text && isRequired ? true : false;
    setError(isRequiredAndNotEmpty);
    if (onBlur) {
      onBlur(fieldName, text);
    }
  };

  useEffect(() => {
    if (value) setText(value);
  }, [value]);

  return (
    <Fragment>
      {isVisible && (
        <TextField
          id={`text-field-${dmdcTargetField}`}
          value={text}
          label={label}
          InputLabelProps={{ variant: 'standard' }}
          name={`text-field-${fieldName}`}
          disabled={isDisabled}
          required={isRequired}
          hidden={isVisible ? false : true}
          type="text"
          onChange={handleChange}
          onBlur={handleValidateField}
          error={error}
          helperText={error && errorMessage}
          style={{ width: '180px' }}
          multiline={isMultiline}
        />
      )}
    </Fragment>
  );
};

export default observer(InputText);
