import { ROUTES } from '../constants/route';
import sessionStorageAccess, { sessionStorageKeys } from './sessionStorageAccess';

export interface AppStateHelper {
  requestToken: {
    setRequestTokenState: () => void;
    getRequestTokenState: () => string;
  };
}

const setRequestTokenState = () => {
  const returnUrl = `${window.location.pathname}${window.location.search}`;
  sessionStorageAccess.setValue(sessionStorageKeys.returnUrl, returnUrl);

  if (window.location.href.includes(ROUTES.REQUEST_TOKEN)) {
    const index = window.location.search.indexOf('=');
    const requestToken = window.location.search.substring(index + 1);

    sessionStorageAccess.setValue(sessionStorageKeys.requestToken, requestToken);
  }
};

const getRequestTokenState = (): string => {
  const requestToken = sessionStorageAccess.getValue(sessionStorageKeys.requestToken);

  if (requestToken) {
    sessionStorageAccess.removeValue(sessionStorageKeys.requestToken);
    sessionStorageAccess.removeValue(sessionStorageKeys.returnUrl);
    return `/${ROUTES.REQUEST_TOKEN}?token=${requestToken}`;
  }

  const returnUrl = sessionStorageAccess.getValue(sessionStorageKeys.returnUrl);

  if (returnUrl) {
    sessionStorageAccess.removeValue(sessionStorageKeys.returnUrl);
  }

  return returnUrl || '/';
};

export const appStateHelper: AppStateHelper = {
  requestToken: {
    setRequestTokenState,
    getRequestTokenState,
  },
};
