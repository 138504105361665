import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    background: 'transparent',
    borderTop: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  title: {
    fontSize: 30,
  },
  text: {
    fontSize: 12,
  },
});
