import React from 'react';

import { Tab } from 'base/models/Asset';
import { useRootStoreContext } from 'base/stores/rootStore';
import { TabContainer } from 'components/EditAsset/TabContainer';
import { observer } from 'mobx-react-lite';
import { EditAssetTitleList } from '../EditAssetTitleList';

interface EditAssetTabListProps {
  selectedTab: number;
}

const EditAssetTabList: React.FC<EditAssetTabListProps> = ({
  selectedTab,
}: EditAssetTabListProps) => {
  const {
    editStore: { tabsInEditStore },
  } = useRootStoreContext();
  const tabIndex = selectedTab - 1; // Index elements are based 0
  return (
    <>
      {tabsInEditStore.map(
        (tab: Tab, index) =>
          tabIndex === index && (
            <TabContainer key={tab.name}>
              <EditAssetTitleList
                titles={tab.titles}
                tabName={tab.name}
                template={tab.template}
                key={`${tab.name}-${index}`}
              />
            </TabContainer>
          )
      )}
    </>
  );
};

export default observer(EditAssetTabList);
