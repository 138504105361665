import React, { forwardRef, useState } from 'react';
import { SortGroup } from 'base/models/Asset';
import { ReactSortable, Sortable } from 'react-sortablejs';
import { GroupWrapper } from '../GroupWrapper';

import { useRootStoreContext } from 'base/stores/rootStore';
import { observer } from 'mobx-react-lite';

const PROUCT_COLLECTION_OPTIONS: Sortable.GroupOptions = {
  name: 'shared',
  put: (from, to, dragEl, event) => {
    return ['orphan-child', 'group-collection'];
  },
  pull: (from, to, dragEl, event) => {
    return true;
  },
};

const CustomGridComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <div className={`d-flex flex-row flex-wrap dmdc-sort-group--container`} ref={ref}>
      {props.children}
    </div>
  );
});

const SortableWrapper: React.FC<{
  openGroupView: (idx: number, group: SortGroup) => void;
}> = ({ openGroupView }) => {
  const { filteredSortGroups, setSortGroups } = useRootStoreContext().groupingStore;

  const [isSortableDisabled, setIsSortableDisabled] = useState<Boolean>(false);
  const disableSorting = (newValue: boolean) => {
    setIsSortableDisabled(newValue);
  };

  return (
    <ReactSortable
      tag={CustomGridComponent}
      group={PROUCT_COLLECTION_OPTIONS}
      animation={0}
      delay={2}
      list={filteredSortGroups}
      ghostClass={'ghost'}
      setList={(currentList) => {
        setSortGroups(currentList);
      }}
      direction="horizontal"
      disabled={Boolean(isSortableDisabled)}
    >
      {filteredSortGroups.map((group, groupIdx) => (
        <div className={`dmdc-sort-group--card`} key={groupIdx}>
          <GroupWrapper
            key={group.id}
            group={group}
            groupIdx={groupIdx}
            openGroupView={openGroupView}
            disableSorting={disableSorting}
          />
        </div>
      ))}
    </ReactSortable>
  );
};
export default observer(SortableWrapper);
