import styled from 'styled-components';

export const AssetProgressSpan = styled.span`
  position: absolute;
  top: 13px;
  left: 8px;
`;

export const AssetProgressContainer = styled.span`
  position: relative;
  padding-right: 15px;
`;
