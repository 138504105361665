import React, { useState, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { IdValueField } from 'base/models/Asset';

export interface LabelProps {
  value?: string | IdValueField;
  isVisible: boolean;
  fieldName: string;
  isIdValue?: boolean;
}

export const Label = ({ value, isVisible, fieldName, isIdValue }: LabelProps) => {
  const [labelValue, setLabelValue] = useState<string>();

  useEffect(() => {
    if (isIdValue) {
      const labelValuesAsSelect = value as IdValueField;
      setLabelValue(labelValuesAsSelect.value);
    } else {
      setLabelValue(value as string);
    }
  }, [value]);

  return <>{isVisible && <Typography id={`label-${fieldName}`}>{labelValue}</Typography>}</>;
};

export default Label;
