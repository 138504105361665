import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from '@material-ui/core';
import { useStyles } from './ConfirmModal.styles';

interface ConfirmModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
  message?: string;
  title?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  setOpen,
  onConfirm,
  message,
  title,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.DialogTitle}>{title}</DialogTitle>
      <DialogContent>{children ?? <Typography>{message}</Typography>}</DialogContent>
      <DialogActions>
        <Button color="default" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
