import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    textareaStyle: {
      display: 'block',
      width: '40%',
      height: '150px',
      padding: '5px 8px 5px 8px',
      margin: '5px',
      border: '1px solid black;',
      fontSize: '14px',
      borderRadius: '5px',
    },
    buttonStyle: {
      backgroundColor: '#216085',
      border: '2px solid #008CBA',
      color: 'white',
      padding: '8px 16px',
      textAlign: 'center',
      display: 'inline-block',
      fontSize: '14px',
      margin: '4px 2px',
      transitionDuration: '0.4s',
      cursor: 'pointer',
      borderRadius: '2px',
    },
  })
);
