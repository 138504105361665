import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { observer } from 'mobx-react-lite';

import {
  AssetProgressContainer,
  AssetProgressSpan,
  AssetProgressMessage,
} from './AssetUploadProgress.styles';
import { useRootStoreContext } from 'base/stores/rootStore';
import { AssetStatus } from 'base/enums/AssetStatus';

const AssetUploadProgress: React.FC = () => {
  const { getAssetsInEditStore } = useRootStoreContext().editStore;
  const { getIngestionUploadedAssets, assets } = useRootStoreContext().ingestionStore;

  const totalAssets =
    assets.length +
    getAssetsInEditStore.filter(
      (editAsset) =>
        assets.findIndex((asset) => asset.assetId === editAsset.assetId) === -1 &&
        editAsset.fields['status'] !== AssetStatus.NotPublished &&
        editAsset.fields['status'] !== AssetStatus.Published // Not considering Published & Not Published
    ).length;
  const uploadedAssets =
    getIngestionUploadedAssets.length +
    getAssetsInEditStore.filter(
      (editAsset) => assets.findIndex((asset) => asset.assetId === editAsset.assetId) === -1
    ).length;
  const assetsProgress = totalAssets !== 0 ? Math.round((uploadedAssets / totalAssets) * 100) : 100;

  return (
    <>
      {assetsProgress < 100 && (
        <>
          <AssetProgressMessage>
            {uploadedAssets} of {totalAssets} Assets uploaded
          </AssetProgressMessage>
          <AssetProgressContainer>
            <AssetProgressSpan>
              <strong>{`${assetsProgress}%`}</strong>
            </AssetProgressSpan>
            <CircularProgress variant="determinate" value={assetsProgress} />
          </AssetProgressContainer>
        </>
      )}
    </>
  );
};

export default observer(AssetUploadProgress);
