import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Dashboard } from 'components/Dashboard/';
import { useStyles, UploadButton } from './Home.page.styles';
import { UserFilter } from 'components/Dashboard/UserFilter';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import { ROUTES } from 'base/constants/route';
import localStorageAccess, {
  retrieveUserInformation,
  retrieveLocalUser,
  LocalCurrentUser,
  LocalStorageKeys,
} from 'base/utils/localStorageAccess';
import { useRootStoreContext } from 'base/stores/rootStore';
import { UserDropdown } from 'base/models/DashboardProps';

const Home: React.FC = () => {
  const [userLogged, setUserLogged] = useState<UserDropdown>({
    email: '',
    fullname: '',
  } as UserDropdown);
  const { setUserFilterInStore } = useRootStoreContext().dashboardStore;
  const classes = useStyles();
  const history = useHistory();

  const updateUserSelected = (user: UserDropdown) => {
    if (user == null) {
      user = { fullname: '', email: '' };
    }
    setUserFilterInStore(user);
    const userSelected: LocalCurrentUser = { fullName: user.fullname, email: user.email };
    localStorageAccess.setToLocalStorage(LocalStorageKeys.currentUser, userSelected);
  };

  const handleUpload = () => {
    history.push(`/${ROUTES.UPLOAD}`);
  };

  useEffect(() => {
    setUser();
    function setUser() {
      const currentSettings = retrieveLocalUser();
      const userResponse = retrieveUserInformation();
      const FirstLastName = `${userResponse?.firstName} ${userResponse?.lastName}`;
      const userDropdown = {
        fullname: FirstLastName,
        email: userResponse?.userName as string,
      } as UserDropdown;

      setUserLogged(userDropdown);

      if (!currentSettings?.fullName) {
        setUserFilterInStore(userDropdown);
      } else {
        userDropdown.fullname = currentSettings.fullName;
        userDropdown.email = currentSettings?.email as string;
        setUserFilterInStore(userDropdown);
      }
    }
  }, []);

  return (
    <Box display="flex" flexWrap="wrap">
      <Grid item className={classes.userSelectGrid}>
        <Paper elevation={0}>
          <UserFilter updateUserSelected={updateUserSelected} userLogged={userLogged} />
        </Paper>
      </Grid>
      <Grid item className={classes.buttonGrid}>
        <UploadButton
          variant="contained"
          size="large"
          color="default"
          onClick={handleUpload}
          startIcon={<AddIcon fontSize="large" color="primary" />}
        ></UploadButton>
      </Grid>
      <Grid item xs={12} className={classes.dashboardContainer}>
        <Dashboard />
      </Grid>
    </Box>
  );
};

export default Home;
