import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { dateTypes } from 'base/models/DashboardProps';

interface DateFormatProps {
  date: Date;
  format: dateTypes;
  dateFormat?: string;
}

const DateFormat: React.FC<DateFormatProps> = ({
  date,
  format,
  dateFormat = 'MM/DD/YYYY',
}: DateFormatProps) => {
  let displayDate = '';
  if (format === dateTypes.DATE_TYPE_REMAINING) {
    dayjs.extend(relativeTime);
    displayDate = dayjs().to(dayjs(date));
  } else if (format === dateTypes.DATE_TYPE_STANDARD) {
    displayDate = dayjs(date).format(dateFormat);
  }
  return <> {displayDate} </>;
};

export default DateFormat;
