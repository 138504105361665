import React, { useState, useMemo, useEffect } from 'react';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Autocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete';

import { getAssetTypesByProductTypeId, getTemplates } from 'base/api/assetType';
import { AssetType, AssetTypes, DataResponseAssetType } from 'base/models/AssetType';
import {
  useStyles,
  propsMenuItemsBellowControl,
  ButtonConfirm,
  MainContainer,
} from './CommonMetadata.styles';

import { getTitles } from 'base/api/title';
import {
  TabTitle as TitleFromTab,
  Tab,
  TabTitles as TitlesFromTab,
  Assets,
  IdValueField,
  Asset,
} from 'base/models/Asset';
import { Title, TitleOptions, TitleProductType, Titles } from 'base/types/title';
import { useRootStoreContext } from 'base/stores/rootStore';
import { updateAssets } from 'components/Upload/Upload.functions';
import { TitleItemOption } from '../TitleItemOption';
import { AssetGroupIds } from 'base/enums/AssetGroupIds';

import { observer } from 'mobx-react-lite';
import { Template } from 'base/models/Template';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import QUERY_IDENTIFIERS from 'base/constants/reactQueryIdentifiers';
import { DraftAssetPatchAction } from 'base/enums/DraftAssetPatchAction';

const DateFormat = 'DD MMM YYYY';

const validImageExtensions = ['jpg', 'jpeg', 'png', 'tiff', 'tif', 'eps', 'psb', 'zip', 'psd'];
const validPromoExtensions = ['mov', 'mxf', 'mpg', 'mp4', 'wav', 'wmv', 'doc', 'zip', 'xml'];
const imagesGroupIds: string[] = [
  AssetGroupIds.EpisodicPhotos,
  AssetGroupIds.EventPhotos,
  AssetGroupIds.GalleryPhotos,
  AssetGroupIds.LogosAndArtwork,
  AssetGroupIds.ProductionStills,
  AssetGroupIds.Publicity,
  AssetGroupIds.SpecialShootPhotos,
  AssetGroupIds.WebAndSocialContent,
];
const promosAndTrailersGroupIds: string[] = [
  AssetGroupIds.Broll,
  AssetGroupIds.Clips,
  AssetGroupIds.Featurettes,
  AssetGroupIds.InterviewSoundbites,
  AssetGroupIds.MotionGraphicPackages,
  AssetGroupIds.Promos,
  AssetGroupIds.Tags,
  AssetGroupIds.Trailers,
  AssetGroupIds.TVSpots,
];

interface AssetTypesState {
  assetGroups: AssetTypes;
  selectedAssetGroup: string;
  isLoadingAssetGroups: boolean;
}

const CommonMetadata: React.FC = () => {
  const classes = useStyles();
  const initTitleOptionValue: Title = {
    id: '',
    name: {
      primary: '',
      secondary: '',
    },
    productType: {
      id: 0,
      name: '',
    },
    identifiers: {
      productId: 0,
      wprId: '',
    },
    releaseDate: new Date(),
  };
  const { data: cachedTemplates } = useQuery([QUERY_IDENTIFIERS.ASSET_TEMPLATES], getTemplates, {
    staleTime: Infinity,
  });
  const [titleOptions, setTitleOptions] = useState<TitleOptions>([] as TitleOptions);
  const [dropdownValue, setDropdownValue] = useState<Title>(initTitleOptionValue);
  const {
    assetsCheckedInStore,
    getCommonFieldsInStore,
    getTemplateInStore,
    setAssetsTemplateInStore,
    setAssetsTitleInStore,
    setAssetsProductTypeIdInStore,
    setAssetsGroupNameInStore,
    removeCheckedAssetsInStore,
    isThereAnyAssetCheckedInStore,
  } = useRootStoreContext().ingestionStore;
  const {
    setTabsInEditStore,
    getIsExternalAdding: isExternalAdding,
    getAddAssetExternalData: addAssetExternalData,
    clearExternalDataForAddInStore,
  } = useRootStoreContext().editStore;
  const { titleId, templateId, productTypeId } = getCommonFieldsInStore;

  const [titleInput, setTitleInput] = useState<string>('');
  const [titleInputProductId, setTitleInputProductId] = useState<number>(0);
  const [isTitlesLoading, setIsTitlesLoading] = useState(false);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [assetGroupsState, setAssetGroupsState] = useState<AssetTypesState>({
    assetGroups: [],
    selectedAssetGroup: '',
    isLoadingAssetGroups: false,
  });

  const isSelectAssetDisabled = useMemo(() => !(templateId || titleId), [templateId, titleId]);
  const [isSelectAssetGroupsDisabled, setIsSelectAssetGroupsDisabled] =
    useState(isSelectAssetDisabled);

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const [titleSearchResult, setTitleSearchResult] = useState<Title[] | null>();

  const retrieveTemplateFromCached = (templateId: string) => {
    const templates = cachedTemplates?.data as Template[];
    const template = templates.find((t: Template) => t.id === templateId);
    return template;
  };

  const fetchAssetTypes = async (productTypeId: number) => {
    setAssetGroupsState({
      assetGroups: [],
      selectedAssetGroup: '',
      isLoadingAssetGroups: true,
    });
    const response = await getAssetTypesByProductTypeId(productTypeId);

    if (response) {
      const data: DataResponseAssetType = response.data;
      return data.assetTypes;
    }
  };

  const createTabsAndTitles = async (title: string, assetType: string) => {
    const assets: Asset[] = assetsCheckedInStore.map((asset) => {
      const newProperties = {
        ...asset.uploadProperties,
        isChecked: false,
      };
      const [assetTypeName, templateId, assetTypeId] =
        assetGroupsState.selectedAssetGroup.split('|');

      return {
        ...asset,
        templateId,
        assetGroup: {
          id: assetTypeId,
          name: assetTypeName,
        },
        title: {
          id: asset.title.id,
          cpmProductId: asset.title.cpmProductId,
          name: title,
          productTypeId,

          wprId: asset.title.wprId,
          primaryName: asset.title.primaryName,
          secondaryName: asset.title.secondaryName,
          releaseDate: asset.title.releaseDate,
          productType: asset.title.productType,
        },
        uploadProperties: newProperties,
      };
    });

    const newTitle: TitleFromTab = {
      name: title,
      displayId: titleInputProductId,
      releaseDate: dayjs(assetsCheckedInStore[0].title.releaseDate ?? new Date()).format(
        DateFormat
      ),
      assets,
    };

    const newTab: Tab = {
      name: assetType,
      titles: [] as TitlesFromTab,
      template: undefined,
    };

    setTabsInEditStore(newTab, newTitle);
  };

  const handleAssetTypeChange = async (value: string) => {
    const [assetGroupName, templateId, assetGroupId] = value.split('|');
    setAssetsGroupNameInStore(assetGroupName, assetGroupId); // Saving name in store to check in initTemplate

    if (templateId !== 'default') {
      const template = retrieveTemplateFromCached(templateId);
      if (template) setAssetsTemplateInStore(template);
    }
    setAssetGroupsState({
      assetGroups: assetGroupsState.assetGroups,
      selectedAssetGroup: value,
      isLoadingAssetGroups: false,
    }); // For the value of the select
    setIsConfirmEnabled(true);
  };

  const buildTitleOptions = (titles: Titles) => {
    const titleOptions: TitleOptions = titles.map(
      ({ id, name, productType, identifiers, releaseDate }: Title) => {
        return {
          id,
          name,
          productType,
          identifiers,
          releaseDate,
        };
      }
    );
    return titleOptions;
  };

  const searchTitleType = async (keyword: string) => {
    if (keyword) {
      const titles = await getTitles(keyword);
      if (titles) {
        setTitleSearchResult(titles);
      }
    }
  };

  const handleInputTitleChange = async (keyword: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      setIsTitlesLoading(true);
      setTitleInput(keyword);
      setDropdownValue(initTitleOptionValue);
      if (keyword.length > 1) {
        await searchTitleType(keyword);
      }
      setIsTitlesLoading(false);
    } else if (reason === 'clear') {
      clearStateVariables();
    }
  };

  const clearStateVariables = () => {
    setTitleInput('');
    setDropdownValue(initTitleOptionValue);
    setTitleOptions([]);
    setIsSelectAssetGroupsDisabled(true);
    setAssetGroupsState({
      assetGroups: [],
      selectedAssetGroup: '',
      isLoadingAssetGroups: false,
    });
    setIsConfirmEnabled(false);
  };

  const handleOnChangeTitle = async (titleOption: Title | null) => {
    if (titleOption && titleOption?.name.primary) {
      setDropdownValue(titleOption as Title);
      setTitleInput(titleOption.name.primary);
      setTitleInputProductId(titleOption.identifiers.productId);

      if (titleOption.productType.id) {
        const fetchedAssetTypes = await fetchAssetTypes(titleOption.productType.id);
        if (fetchedAssetTypes) {
          setAssetGroupsState({
            assetGroups: fetchedAssetTypes,
            selectedAssetGroup: '',
            isLoadingAssetGroups: false,
          });
        }
        setIsSelectAssetGroupsDisabled(false);
        setAssetsProductTypeIdInStore(titleOption.productType.id);
        setAssetsTitleInStore(
          titleOption.name.primary,
          titleOption.name.secondary ?? '',
          titleOption.id,
          titleOption.identifiers.productId,
          titleOption.identifiers.wprId ?? '',
          titleOption.releaseDate,
          titleOption.productType
        );
      }
    }
  };

  const fillAssetsWithPresetValues = (assetsFromStore: Assets, assetGroupId: string) => {
    const template: Template | undefined = getTemplateInStore;
    let assetsWithPresetValues = assetsFromStore;

    if (template && template.presets) {
      const { presets, fields } = template;

      const selectedPresets = presets.find(
        (preset) => preset.assetGroupId === parseInt(assetGroupId, 10)
      );
      if (selectedPresets) {
        assetsWithPresetValues = assetsCheckedInStore.map((asset) => {
          selectedPresets.fields.forEach((field) => {
            if (fields.some((f) => f.isIdValue && f.fieldName === field.fieldName)) {
              const splitedPresetValue = field.presetValue.split('|');
              const [idPreset, valuePreset] = splitedPresetValue;
              const idValueField: IdValueField = {
                id: parseInt(idPreset),
                value: valuePreset,
              };
              asset.fields[field.fieldName] = idValueField;
            } else {
              asset.fields[field.fieldName] = field.presetValue;
            }
          });
          return {
            ...asset,
          };
        });
      }
    }

    return assetsWithPresetValues;
  };

  const setGroupChoice = (assets: Assets) => {
    const [firstAsset] = assets;
    const assetGroupId = firstAsset.assetGroup.id;
    if (!imagesGroupIds.includes(assetGroupId) && !promosAndTrailersGroupIds.includes(assetGroupId))
      return;

    const firstAssetFileName = firstAsset.fields.fileName;
    const firstAssetFileNameWithoutExtension = firstAssetFileName.substring(
      0,
      firstAssetFileName.lastIndexOf('.')
    );

    const isSameFileName: boolean = assets.every((asset) => {
      const fileName = asset.fields.fileName.substring(0, asset.fields.fileName.lastIndexOf('.'));
      return fileName === firstAssetFileNameWithoutExtension;
    });

    if (isSameFileName) {
      assets.forEach((asset) => {
        const fileExtension = asset.fields.fileName.substring(
          asset.fields.fileName.lastIndexOf('.') + 1
        );
        const extensionsToValidate = imagesGroupIds.includes(assetGroupId)
          ? validImageExtensions
          : validPromoExtensions;

        if (extensionsToValidate.includes(fileExtension)) {
          asset.fields.groupChoice = firstAssetFileNameWithoutExtension;
        }
      });
    }

    setIsParentCheckbox(assets);
  };

  const setIsParentCheckbox = (assets: Assets) => {
    assets.forEach((asset) => {
      const fileExtension = asset.fields.fileName.substring(
        asset.fields.fileName.lastIndexOf('.') + 1
      );
      asset.fields.isParent =
        fileExtension && ['jpeg', 'jpg'].includes(fileExtension.toLowerCase());
    });
  };

  const handleConfirm = async () => {
    setIsConfirmLoading(true);
    const { selectedAssetGroup } = assetGroupsState;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [assetGroupName, templateId, assetGroupId] = selectedAssetGroup.split('|');

    if (isExternalAdding && addAssetExternalData) {
      setAssetsProductTypeIdInStore(addAssetExternalData?.title.productType.id as number);
      setAssetsTitleInStore(
        addAssetExternalData?.title.name.primary as string,
        addAssetExternalData?.title.name.secondary ?? '',
        addAssetExternalData?.title.id as string,
        addAssetExternalData?.title.identifiers.productId as number,
        addAssetExternalData?.title.identifiers.wprId ?? '',
        addAssetExternalData?.title.releaseDate as Date,
        addAssetExternalData?.title.productType as TitleProductType
      );
      setAssetsGroupNameInStore(assetGroupName, assetGroupId);
      setAssetsTemplateInStore(addAssetExternalData?.template as Template); //This is also here to update the assets with templateId reference
      clearExternalDataForAddInStore();
    }
    const assetsWithPresetValues = fillAssetsWithPresetValues(assetsCheckedInStore, assetGroupId);

    await updateAssets({
      action: DraftAssetPatchAction.TitleAssign,
      assets: assetsWithPresetValues,
    });
    setGroupChoice(assetsWithPresetValues);
    createTabsAndTitles(titleInput, assetGroupName); // Create tabs and titles
    removeCheckedAssetsInStore(); // It will only remove those checked assets that are already uploaded
    clearStateVariables();
    setIsConfirmLoading(false);
  };

  // This useEffect is for Adding External Assets ONLY
  useEffect(() => {
    const prepareTitleAndAssetTypePreSelection = () => {
      const titleOptions: TitleOptions = [addAssetExternalData?.title as Title];
      setTitleOptions(titleOptions);
      setDropdownValue(addAssetExternalData?.title as Title);
      setTitleInput(addAssetExternalData?.title.name.primary as string);
      setTitleInputProductId(addAssetExternalData?.title.identifiers.productId as number);

      const { assetTypeName, templateId, assetTypeId } =
        addAssetExternalData?.assetType as AssetType;
      setIsSelectAssetGroupsDisabled(false);
      setAssetGroupsState({
        assetGroups: addAssetExternalData?.assetTypes as AssetTypes,
        selectedAssetGroup: `${assetTypeName}|${templateId}|${assetTypeId}`,
        isLoadingAssetGroups: false,
      });

      setAssetsTemplateInStore(addAssetExternalData?.template as Template); // This is here to set the template selected
    };

    if (isExternalAdding && addAssetExternalData) prepareTitleAndAssetTypePreSelection();
  }, [isExternalAdding, addAssetExternalData]);

  useEffect(() => {
    // This way if we are Externally adding assets, we enable Confirm button until we have
    // assets and they are selected
    if (assetsCheckedInStore.length > 0 && isExternalAdding) {
      setIsConfirmEnabled(true);
    }
  }, [assetsCheckedInStore]);

  useEffect(() => {
    if (titleSearchResult) {
      const titleOptions: TitleOptions = buildTitleOptions(titleSearchResult);
      if (titleOptions) setTitleOptions(titleOptions);
    }
  }, [titleSearchResult]);

  return (
    <MainContainer>
      <div className={classes.root}>
        <div className={classes.inputTextContainer}>
          <Autocomplete
            disabled={!isThereAnyAssetCheckedInStore && !isExternalAdding}
            id="autocomplete-title-search"
            data-testid="autocomplete"
            inputValue={titleInput}
            value={dropdownValue as Title}
            placeholder="Type a Title"
            noOptionsText="No results"
            loading={isTitlesLoading}
            onChange={(e, value) => handleOnChangeTitle(value as Title)}
            onInputChange={(e, value, reason) => handleInputTitleChange(value, reason)}
            filterOptions={(options) => options}
            getOptionSelected={(option, selected) =>
              option.productType.id === selected.productType.id
            }
            getOptionLabel={(option: Title) =>
              `${option.name.primary} ${option.identifiers.productId}` || ''
            }
            renderOption={(option: Title) => <TitleItemOption option={option} />}
            options={titleOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                data-testid="text-title-search"
                id="text-title-search"
                label="Title"
                className={classes.titleSearch}
                variant="outlined"
              />
            )}
          />
        </div>
        <div className={classes.inputTextContainer}>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="label-asset-groups" htmlFor="select-asset-groups">
              Asset Groups
            </InputLabel>
            <Select
              labelId="label-asset-groups"
              id="select-asset-groups"
              data-testid="asset-type"
              onChange={(event) => handleAssetTypeChange(event.target.value as string)}
              defaultValue=""
              disabled={isSelectAssetGroupsDisabled}
              value={assetGroupsState.selectedAssetGroup}
              MenuProps={{ ...propsMenuItemsBellowControl }}
              variant="outlined"
              label="Asset Groups"
            >
              {!assetGroupsState.isLoadingAssetGroups ? (
                assetGroupsState.assetGroups.map(
                  ({ templateId, assetTypeId, assetTypeName }: AssetType) => (
                    <MenuItem
                      key={assetTypeId}
                      value={`${assetTypeName}|${templateId}|${assetTypeId}`}
                    >
                      {assetTypeName}
                    </MenuItem>
                  )
                )
              ) : (
                <MenuItem disabled>Loading...</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
      <div>
        <ButtonConfirm
          disabled={!isConfirmEnabled || isConfirmLoading}
          disableRipple
          variant="contained"
          onClick={handleConfirm}
        >
          {isConfirmLoading ? <CircularProgress color="inherit" size={22} /> : <>Confirm</>}
        </ButtonConfirm>
      </div>
    </MainContainer>
  );
};

export default observer(CommonMetadata);
