export const ROUTES = {
  INGEST: 'ingest',
  UPLOAD: 'upload',
  EDIT: 'edit',
  LOGIN: 'login',
  LOGIN_CALLBACK: 'auth/myidcallback',
  LOGIN_CALLBACK_DMDC: 'auth/dmdccallback',

  REQUEST_TOKEN: 'asset/request',
  EDIT_FROM_APPLICATION: 'asset/edit',
  ADD_FROM_APPLICATION: 'asset/add',

  ASSET_SORT: 'asset/sort',
  REAUTH: 'reauth',
  UPLOADSCREENERS: 'UploadScreeners',
};
