import { postTransferSpec } from 'base/api/aspera';
import { Asset, Assets } from 'base/models/Asset';
import { AsperaPaths, AsperaPath } from 'base/types/aspera';
import { getTransferId } from 'components/Upload/Upload.aspera';
import { putAssets } from 'base/api/asset';
import { DraftReplacement } from 'base/models/Replacement';
import { DraftAssetPatchAction } from 'base/enums/DraftAssetPatchAction';

export const getTransferSpec = async (asperaPaths: AsperaPaths) => {
  return await postTransferSpec(asperaPaths);
};

export const executeTransfer = (
  data: any,
  newIncomingAssets: Assets,
  aspera: any,
  setIsStartingUploadInStore: (value: boolean) => void,
  setAssetsInStore: (assets: Assets) => void,
  updateAssetsWithTransferId: (assets: Assets) => void,
  isReplacement?: boolean
) => {
  const connectSpec = { allow_dialogs: true };
  const [firstTransferSpecElement] = data.transfer_specs;

  const transferSpec = {
    ...firstTransferSpecElement.transfer_spec,
    authentication: 'token', // this is important
  };
  if (data) {
    aspera.client.startTransfer(transferSpec, connectSpec, {
      success: async (upload: any) => {
        setIsStartingUploadInStore(false);

        if (!isReplacement) {
          const succededTransferId = getTransferId(upload.transfer_specs);

          const assetsToUpdate = newIncomingAssets.map((incomingAsset: Asset) => {
            return {
              ...incomingAsset,
              asperaTransferId: succededTransferId,
            };
          });
          setAssetsInStore(newIncomingAssets);
          await putAssets({
            action: DraftAssetPatchAction.UploadDone,
            assets: assetsToUpdate,
          });

          // setup transferId in Asset
          updateAssetsWithTransferId(assetsToUpdate);
        }
      },
      error: (error: any) => error,
    });
  }
};

export const getDraftReplacement = (
  currentAsset: Asset,
  asperaPath: AsperaPath,
  newAsset: Asset
) => {
  const draftReplacement: DraftReplacement = {
    assetId: currentAsset.id,
    jobId: currentAsset.jobId,

    fileName: newAsset.fields.fileName,
    filePath: newAsset.fields.filePath,
    size: asperaPath.size,
    shortId: currentAsset.fields['mediaID'] ?? '',
    lastModified: newAsset.modified?.date.toString() ?? '',
  };
  return draftReplacement;
};
