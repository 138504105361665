export const getLastModifiedDate = (assets: any): Date => {
  return new Date(
    Math.max.apply(
      null,
      assets.map(function (asset: any) {
        return new Date(asset.modified?.date);
      })
    )
  );
};
