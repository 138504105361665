import { observable, action, makeObservable, computed } from 'mobx';

import { RootStore } from './rootStore';
import { Assets, Asset, AssetUploadProperties } from 'base/models/Asset';
import Job from 'base/models/Job';
import { Template } from 'base/models/Template';
import { CommonFields } from './editStore';
import { TitleProductType } from 'base/types/title';

export default class IngestionStore {
  rootStore: RootStore;
  constructor(rootstore: RootStore) {
    makeObservable(this);
    this.rootStore = rootstore;
  }

  @observable assets: Assets = [];
  @observable job: Job | null = null;
  @observable isError = false;
  @observable errorText = '';
  @observable isAsperaTransferActive = false;
  @observable isStartingUpload = false;

  @observable template: Template | undefined = undefined;

  // Job Actions
  @action getCurrentJobFromStore = () => {
    return this.job;
  };

  @action setCurrentJobInStore = (job: Job) => {
    this.job = job;
  };

  @action initializeJobInStore = () => {
    this.job = {
      createdAt: new Date(),
      updatedAt: new Date(),
      totalAssets: 0,
    } as Job;
    return this.job;
  };

  // Asset Actions
  @action setAssetsInStore = (assets: Assets) => {
    this.assets = [...this.assets, ...assets];
  };

  @action removeAssetsFromStore = () => {
    this.assets = [];
  };

  @action removeAssetByIdFromStore = (assetId: string | null) => {
    if (assetId) {
      this.assets = this.assets.filter((x) => x.id !== assetId);
    }
  };

  @action removeCheckedAssetsInStore = () => {
    this.assets = this.assets.filter(({ uploadProperties }: Asset) => !uploadProperties.isChecked);
  };

  // Cancelation
  @action setAssetAsCancelledByIdInStore = (assetId: string) => {
    this.assets = this.assets.map((asset) => {
      if (assetId === asset.id) {
        const uploadProperties: AssetUploadProperties = {
          ...asset.uploadProperties,
          isCancelled: true,
          progress: 0,
        };
        return { ...asset, uploadProperties };
      } else {
        return { ...asset };
      }
    });
  };

  // Check Actions
  @action setAssetCheckByIdInStore = (assetId: string, value: boolean) => {
    this.assets = this.assets.map((asset) => {
      if (assetId === asset.id) {
        const uploadProperties: AssetUploadProperties = {
          ...asset.uploadProperties,
          isChecked: value,
        };
        return { ...asset, uploadProperties };
      } else {
        return { ...asset };
      }
    });
  };

  @action setAllAssetsChecksInStore = (checkValue: boolean) => {
    this.assets = this.assets.map((asset) => {
      return {
        ...asset,
        uploadProperties: {
          ...asset.uploadProperties,
          isChecked: checkValue,
        },
      };
    });
  };

  @action setAssetHasStartUploadByIdInStore = (assetId: string) => {
    this.assets.forEach(({ uploadProperties, id }) => {
      if (assetId === id) {
        uploadProperties.hasStartUpload = true;
      }
    });
  };

  @action setIsStartingUploadInStore = (value: boolean) => {
    this.isStartingUpload = value;
  };

  @action setAsperaAssetProgressInStore = (
    assetId: string,
    transferProgress: number,
    hasError = false
  ) => {
    if (hasError) {
      this.assets = this.assets.map((asset) => {
        if (assetId === asset.id) {
          const { uploadProperties } = asset;
          return {
            ...asset,
            uploadProperties: {
              ...uploadProperties,
              hasError,
            },
          };
        } else {
          return { ...asset };
        }
      });
    } else {
      this.assets = this.assets.reduce((previousValue, currentValue) => {
        if (assetId === currentValue.id) {
          const isUploaded = transferProgress === 100;

          const newAsset: Asset = {
            ...currentValue,
            uploadProperties: {
              ...currentValue.uploadProperties,
              progress: transferProgress,
              isUploaded,
            },
          };
          return [...previousValue, newAsset];
        } else return [...previousValue, currentValue];
      }, [] as Assets);
    }
  };

  // Errors Actions
  @action setIngestErrorInStore = (value: boolean, text?: string) => {
    this.isError = value;
    this.errorText = text ?? 'There was an error';
  };

  @action setAssetsTemplateInStore = (template: Template) => {
    this.template = template;
    this.assets.forEach((asset) => {
      asset.templateId = template.id;
    });
  };

  @action setAssetFieldInStore = (fieldName: string, value: string | boolean) => {
    const newAssets = this.assets.reduce((newList, asset) => {
      if (asset.uploadProperties.isChecked) {
        asset.fields[fieldName] = value;
      }
      return [...newList, asset];
    }, [] as Assets);
    this.assets = newAssets;
  };

  @action setAssetsProductTypeIdInStore = (productTypeId: number) => {
    const newAssets = this.assets.reduce((newList, asset) => {
      asset.title.productTypeId = productTypeId;
      return [...newList, asset];
    }, [] as Assets);
    this.assets = [...newAssets];
  };

  @action setAssetsGroupNameInStore = (assetTypeName: string, assetGroupId: string) => {
    const newAssets = this.assets.reduce((newList, asset) => {
      if (asset.uploadProperties.isChecked) {
        asset.assetGroup = {
          id: assetGroupId,
          name: assetTypeName,
        };
      }
      return [...newList, asset];
    }, [] as Assets);
    this.assets = [...newAssets];
  };

  @action deleteJobFromStore = () => {
    this.job = null;
  };

  @action setAsperaTransferStatus = (status: boolean) => {
    this.isAsperaTransferActive = status;
  };

  @action updateAssetsWithTransferId = (incomingAssets: Assets) => {
    this.assets = this.assets.map((asset: Asset) => {
      const assetToUpdate = incomingAssets.find(
        (incomingAsset: Asset) => asset.id === incomingAsset.id
      );
      if (assetToUpdate) {
        return {
          ...assetToUpdate,
        };
      } else {
        return {
          ...asset,
        };
      }
    });
  };

  @action restoreAssetsToStore = (assets: Assets) => {
    const map = new Map();
    // important to concat store assets and not backwards, to preserve latests values
    const duplicatedAssets = assets.concat(this.assets);

    duplicatedAssets.forEach((asset: Asset) => {
      if (!map.has(asset.id)) map.set(asset.id, asset);
    });
    this.assets = Array.from(map.values());
  };
  @computed get isAsperaTransferActiveInStore() {
    return this.isAsperaTransferActive;
  }

  @computed get isStartingUploadInStore() {
    return this.isStartingUpload;
  }

  // Computed Getters
  @computed get isThereAnyAssetInStore() {
    return this.assets.length > 0;
  }

  @computed get isThereAnyAssetCheckedInStore() {
    return this.assets.some((asset) => asset.uploadProperties.isChecked);
  }

  @computed get assetsCheckedInStore() {
    return this.assets.filter((asset) => asset.uploadProperties.isChecked);
  }

  @computed get areAllAssetsCheckedInStore() {
    if (this.assets.length > 0) {
      return this.assets.every(({ uploadProperties }) => uploadProperties.isChecked);
    }
    return false;
  }

  @computed get assetsInStore() {
    return this.assets;
  }

  @computed get getAssetTypeIdInTemplateStore() {
    const assetTypeId = this.assets?.[0]?.assetGroup.id;
    return assetTypeId;
  }

  @computed get getTemplateIdInStore() {
    const firstTemplateId = this.assets?.[0]?.templateId;
    if (this.assets.some((asset) => asset.templateId !== firstTemplateId)) {
      return undefined;
    }
    return firstTemplateId;
  }

  @action setAssetsTitleInStore = (
    primaryName: string,
    secondaryName: string,
    id: string,
    productId: number,
    wprId: string,
    releaseDate: Date,
    productType: TitleProductType
  ) => {
    const newAssets = this.assets.reduce((newList, asset) => {
      if (asset.uploadProperties.isChecked) {
        asset.title.name = primaryName ?? secondaryName;
        asset.title.primaryName = primaryName;
        asset.title.secondaryName = secondaryName;
        asset.title.id = id;
        asset.title.cpmProductId = productId;
        asset.title.releaseDate = releaseDate;
        asset.title.productType = productType;
        asset.title.wprId = wprId;
      }
      return [...newList, asset];
    }, [] as Assets);
    this.assets = [...newAssets];
  };

  @computed get getCommonFieldsInStore() {
    const commonFields = {} as CommonFields;
    if (!this.assets || this.assets.length === 0) {
      return commonFields;
    }

    const firstAsset: Asset = this.assets[0];
    const firstAssetFields = firstAsset.fields;

    if (!firstAssetFields) {
      return commonFields;
    }

    const firstAssetTitle = firstAsset.title.name;
    if (!this.assets.some((asset) => asset.title.name !== firstAssetTitle)) {
      commonFields.title = firstAssetTitle;
    }
    const firstAssetTitleId = firstAsset.title.id;
    if (!this.assets.some((asset) => asset.title.id !== firstAssetTitleId)) {
      commonFields.title = firstAssetTitleId;
    }
    const firstAssetProductTypeId = firstAsset.title.productTypeId;
    if (!this.assets.some((asset) => asset.title.productTypeId !== firstAssetProductTypeId)) {
      commonFields.productTypeId = firstAssetProductTypeId;
    }
    const firstTemplateId = firstAsset.templateId;
    if (!this.assets.some((asset) => asset.templateId !== firstTemplateId)) {
      commonFields.templateId = firstTemplateId;
    }
    const firstAssetTypeName = firstAsset.assetGroup.name;
    if (!this.assets.some((asset) => asset.assetGroup.name !== firstAssetTypeName)) {
      commonFields.assetTypeName = firstAssetTypeName;
    }

    return commonFields;
  }

  @computed get getTemplateInStore() {
    return this.template;
  }

  @computed get getIsAnyAssetInProgressYet() {
    return this.assets.some(({ uploadProperties }: Asset) => !uploadProperties.isUploaded);
  }
  @computed get getIngestionAssetsInProgress() {
    return this.assets.filter((asset) => !asset.uploadProperties.isUploaded).length;
  }
  @computed get getIngestionUploadedAssets() {
    return this.assets.filter((asset) => asset.uploadProperties.isUploaded);
  }
}
