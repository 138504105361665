import React from 'react';

import { TabTitles, TabTitle } from 'base/models/Asset';
import { EditAssetTitle } from '../EditAssetTitle';
import { Template } from 'base/models/Template';

interface EditAssetTitleListProps {
  titles: TabTitles;
  tabName: string;
  template?: Template;
}

const EditAssetTitleList: React.FC<EditAssetTitleListProps> = ({
  titles,
  tabName,
  template,
}: EditAssetTitleListProps) => {
  return (
    <>
      {titles.map((title: TabTitle, index) => (
        <EditAssetTitle
          titleName={title.name}
          assets={title.assets}
          titleProductId={title.displayId}
          titleReleaseDate={title.releaseDate}
          tabName={tabName}
          template={template}
          key={`${title.name}-${index}`}
        />
      ))}
    </>
  );
};

export default EditAssetTitleList;
