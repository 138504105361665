import React, { useCallback, useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { getTemplateById } from 'base/api/assetType';
import { getDraftsByRequestId } from 'base/api/draft';
import { Asset } from 'base/models/Asset';
import { Template } from 'base/models/Template';
import { useRootStoreContext } from 'base/stores/rootStore';
import { getAssetsWithUploadProperties } from 'components/Dashboard/Dashboard.functions';
import { EditAsset } from 'components/EditAsset';
import { Breadcrumb } from 'components/Upload/Breadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './EditFromApp.styles';

const EditFromApp = () => {
  const classes = useStyles();
  const { startTabsInEditStore, tabsInEditStore, setIngestErrorInStore, setRequestIdInStore } =
    useRootStoreContext().editStore;
  const [externalAssetGroup, setExternalAssetGroupName] = useState('');
  const searchParameter = useLocation().search;
  const history = useHistory();

  const fetchTemplate = useCallback(async (templateId: string) => {
    const { response } = await getTemplateById(templateId);
    if (response && response.data) {
      return response.data;
    }
  }, []);

  const setError = useCallback((errorMessage: string) => {
    setIngestErrorInStore(true, errorMessage);
    setTimeout(() => {
      history.push(`/`);
    }, 3000);
  }, []);

  useEffect(() => {
    const setupDraftAssets = async (draftAssets: any) => {
      const assetsWithUploadProperties = getAssetsWithUploadProperties(draftAssets);

      const assetThatContainstAssetGroup: Asset | undefined = draftAssets.find(
        (asset: Asset) => asset.assetGroup && asset.assetGroup.name
      );
      setExternalAssetGroupName(assetThatContainstAssetGroup?.assetGroup.name ?? '');

      let template: Template;
      const [firstAsset] = draftAssets;
      if (firstAsset && firstAsset.templateId) {
        template = await fetchTemplate(firstAsset.templateId);
        startTabsInEditStore(assetsWithUploadProperties, template);
      }
    };

    const getDraftAssets = async () => {
      const queryParams = new URLSearchParams(searchParameter);
      const requestId = queryParams.get('requestId');

      if (requestId) {
        setRequestIdInStore(requestId); // for commit, save the request id
        const { response, error } = await getDraftsByRequestId(requestId);

        if (!error && response) {
          const draftAssets = response.data.draftAssets;
          setupDraftAssets(draftAssets);
        } else setError('Error trying to get Assets.');
      } else setError('Error Request Id.');
    };

    getDraftAssets();
  }, []);

  return (
    <Box className={classes.root}>
      <Grid container spacing={3} justify="flex-start" alignItems="center">
        <Grid item xs={12} lg={2}>
          <Breadcrumb />
        </Grid>

        {tabsInEditStore.length > 0 && (
          <>
            <Grid item xs={12}>
              <EditAsset externalAssetGroupName={externalAssetGroup} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default observer(EditFromApp);
