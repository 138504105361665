import React, { Fragment, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@material-ui/core';
import BaseFieldProps from 'base/models/BaseFieldProps';
import { TemplateFieldOption } from 'base/models/Template';

import { useStyles, propsMenuItemsBellowControl } from './InputSubSelect.styles';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { IdValueField } from 'base/models/Asset';

export interface InputSubSelectProps extends BaseFieldProps {
  defaultValue?: any;
  errorMessage?: string;
  parentFieldName?: string;
  parentFieldValue?: string;
  childFieldName?: string;
  options?: TemplateFieldOption[];
}
const InputSubSelect: React.FC<InputSubSelectProps> = ({
  defaultValue,
  label,
  errorMessage,
  isRequired,
  isDisabled,
  isIdValue,
  isVisible,
  fieldName,
  dmdcTargetField,
  childFieldName,
  parentFieldName,
  parentFieldValue,
  options,
  onChange,
  onBlur,
  value,
}: InputSubSelectProps) => {
  const classes = useStyles();

  const [localDefaultValue, setLocalDefaultValue] = useState(defaultValue);
  const [selectedOption, setSelectedOption] = useState(value);
  const [localOptions, setLocalOptions] = useState<TemplateFieldOption[] | undefined>([]);
  const [displaySelectedOption, setDisplaySelectedOption] = React.useState('');
  const [error, setError] = useState(false);

  const filterOptions = (newParentFieldValue: any, options: TemplateFieldOption[]) => {
    const [templateFieldOption] = options?.filter(
      (option) => option.parentFieldValue === newParentFieldValue
    );

    if (templateFieldOption) setLocalOptions(templateFieldOption.options);
    if (defaultValue) {
      if (isIdValue) {
        defaultValue = `${defaultValue.id}|${defaultValue.value}`;
      }
      setLocalDefaultValue(defaultValue);
      setSelectedOption(defaultValue);
    } else {
      setSelectedOption('');
      setLocalDefaultValue('');
    }
  };

  useEffect(() => {
    if (options && parentFieldValue) filterOptions(parentFieldValue, options);
  }, [parentFieldValue, options]);

  const handleChange = (targetValue: string) => {
    if (isIdValue) {
      // we receive the e.target.value as a string: `id|value`
      const splitedTargetValue = targetValue.split('|');
      const [, value] = splitedTargetValue;
      setDisplaySelectedOption(value);
    }

    setSelectedOption(targetValue);
    if (onChange) {
      onChange(fieldName, targetValue);
    }
  };

  const handleValidateField = () => {
    const isRequiredAndEmpty = selectedOption.length === 0 && isRequired;
    setError(isRequiredAndEmpty);
    if (onBlur) {
      // isIdValue will tell us from the template if needs to be an object with id-value
      if (isIdValue && selectedOption) {
        const splitedSelectedOption = selectedOption.split('|');
        const [, displayValue] = splitedSelectedOption;

        const idValueField: IdValueField = {
          id: parseInt(selectedOption),
          value: displaySelectedOption ? displaySelectedOption : displayValue,
        };
        onBlur(fieldName, idValueField);
      } else {
        onBlur(fieldName, selectedOption);
      }
    }
  };

  return (
    <Fragment>
      {isVisible && (
        <FormControl
          className={classes.formControl}
          disabled={isDisabled}
          required={isRequired}
          hidden={isVisible ? false : true}
        >
          <InputLabel id={`label-${fieldName}`}>{label}</InputLabel>
          <Select
            labelId={`label-${fieldName}`}
            id={`select-${dmdcTargetField}`}
            value={!selectedOption ? localDefaultValue : selectedOption}
            name={`select-${fieldName}`}
            onChange={(event) => handleChange(event.target.value as string)}
            onBlur={handleValidateField}
            error={error}
            data-testid={`select-${dmdcTargetField}`}
            MenuProps={{ ...propsMenuItemsBellowControl }}
          >
            {localOptions?.map((option: TemplateFieldOption) => (
              <MenuItem
                key={option.value}
                value={isIdValue ? `${option.value}|${option.display}` : option.value}
              >
                {option.display}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText error>{errorMessage}</FormHelperText>}
        </FormControl>
      )}
    </Fragment>
  );
};

export default observer(InputSubSelect);
