import { PreviewState } from 'base/models/Preview';
import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from './rootStore';

export class PreviewStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable previewsMap: Map<string, PreviewState> = new Map<string, PreviewState>();

  @action addPreview = (assetId: string, base64Small: string, base64Large: string) => {
    const previewState: PreviewState = { Base64Small: base64Small, Base64Large: base64Large };
    this.previewsMap.set(assetId, previewState);
  };

  @action deletePreview = (assetId: string) => {
    this.previewsMap.delete(assetId);
  };

  @action clearMap = () => {
    this.previewsMap.clear();
  };

  @computed get getPreviewMaps() {
    return this.previewsMap;
  }
}
