import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    buttonGrid: {
      textAlign: 'end',
      marginTop: '-20px',
      zIndex: 100,
      position: 'sticky',
      top: '70px',
      display: 'flex',
      alignItems: 'stretch',
    },
    userSelectGrid: {
      marginTop: '-20px',

      zIndex: 100,
      position: 'sticky',
      top: '70px',
      flexGrow: 1,
    },
    dashboardContainer: {
      paddingTop: 24,
    },
  })
);

export const UploadButton = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    marginLeft: 16,
    display: 'flex',
    backgroundColor: '#fff',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .MuiSvgIcon-root.MuiSvgIcon-colorPrimary.MuiSvgIcon-fontSizeLarge': {
      fontSize: 40,
    },
  },
}))(Button);
