import React from 'react';
import { TemplateFieldOption, Preset } from 'base/models/Template';
import { InputTextArea } from 'components/shared/InputTextArea';
import { InputText } from 'components/shared/InputText';
import { InputSelect } from 'components/shared/InputSelect';
import { InputSubSelect } from 'components/shared/InputSubSelect';
import { InputDate } from 'components/shared/InputDate';
import { useRootStoreContext } from 'base/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { MultiSelect } from 'components/shared/MultiSelect';
import { CheckBox } from 'components/shared/CheckBox';
import { Label } from 'components/shared/Label';

const FIELD_TYPES = {
  TEXT_AREA: 'textArea',
  TEXT: 'text',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  DATE: 'date',
  MULTISELECT: 'multiSelect',
  LABEL: 'label',
};

interface DynamicFieldItemProps {
  fieldType: string;
  fieldName: string;
  label: string;
  isVisible: boolean;
  isRequired: boolean;
  isDisabled: boolean;
  isIdValue?: boolean;
  options?: TemplateFieldOption[];
  childFieldName?: string;
  validationErrorMessage?: string;
  dmdcTargetField: string;
  presets: Preset | undefined;
  value?: string | boolean;
  onFieldChange?: (fieldName: string, value: string) => void;
  onFieldBlur?: (fieldName: string, value: any) => void;

  tabName?: string;
  titleName?: string;
  assetId?: string;

  isBulkEdit?: boolean;
}
const DynamicFieldItem = ({
  fieldType,
  label,
  fieldName,
  isIdValue,
  isDisabled,
  isRequired,
  isVisible,
  options,
  childFieldName,
  validationErrorMessage,
  dmdcTargetField,
  presets,
  value,
  onFieldChange,
  onFieldBlur,

  tabName = '',
  titleName = '',
  assetId = '',
  isBulkEdit,
}: DynamicFieldItemProps) => {
  const { getInputSelectRule, getBulkInputSelectRule } = useRootStoreContext().editStore;

  const isParentInputSelect = (options: TemplateFieldOption[]): boolean => {
    return !options[0].parentFieldValue;
  };

  const handleFieldChange = (fieldName: string, value: any) => {
    if (onFieldChange) {
      onFieldChange(fieldName, value);
    }
  };

  const handleFieldBlur = (fieldName: string, value: any) => {
    if (onFieldBlur) {
      onFieldBlur(fieldName, value);
    }
  };

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      return (
        <InputText
          label={label}
          fieldName={fieldName}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isVisible={isVisible}
          isMultiline={true}
          errorMessage={isRequired ? validationErrorMessage : ''}
          dmdcTargetField={dmdcTargetField}
          onBlur={handleFieldBlur}
          onChange={handleFieldChange}
          value={value as string}
        />
      );
    case FIELD_TYPES.SELECT:
      if (options && isParentInputSelect(options)) {
        return (
          <InputSelect
            label={label}
            errorMessage={validationErrorMessage}
            isRequired={isRequired}
            isDisabled={isDisabled}
            isVisible={isVisible}
            isIdValue={isIdValue}
            fieldName={fieldName}
            dmdcTargetField={dmdcTargetField}
            childFieldName={childFieldName}
            options={options}
            value={(value as string) ?? ''}
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
            assetId={assetId}
            tabName={tabName}
            titleName={titleName}
            isBulkEdit={isBulkEdit}
          />
        );
      } else {
        let objectRule;
        if (isBulkEdit) {
          objectRule = getBulkInputSelectRule(fieldName);
        } else {
          objectRule = getInputSelectRule(fieldName, tabName, titleName, assetId);
        }

        return (
          <InputSubSelect
            label={label}
            errorMessage={validationErrorMessage}
            isRequired={isRequired}
            isDisabled={isDisabled}
            isVisible={isVisible}
            isIdValue={isIdValue}
            fieldName={fieldName}
            dmdcTargetField={dmdcTargetField}
            childFieldName={childFieldName}
            options={options}
            defaultValue={value}
            parentFieldName={objectRule?.parentFieldName}
            parentFieldValue={objectRule?.parentFieldValue}
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
          />
        );
      }
    case FIELD_TYPES.TEXT_AREA:
      return (
        <InputTextArea
          isDisabled={isDisabled}
          isVisible={isVisible}
          isRequired={isRequired}
          fieldName={fieldName}
          dmdcTargetField={dmdcTargetField}
          label={label}
          rowsMax={10}
          rowsMin={3}
          onChange={handleFieldChange}
          onBlur={handleFieldBlur}
          value={value as string}
        />
      );
    case FIELD_TYPES.DATE:
      return (
        <InputDate
          label={label}
          fieldName={fieldName}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isVisible={isVisible}
          errorMessage={isRequired ? validationErrorMessage : ''}
          dmdcTargetField={dmdcTargetField}
          onChange={handleFieldChange}
          onBlur={handleFieldBlur}
          value={value as string}
        />
      );
    case FIELD_TYPES.MULTISELECT:
      if (options) {
        return (
          <MultiSelect
            label={label}
            errorMessage={validationErrorMessage}
            isRequired={isRequired}
            isDisabled={isDisabled}
            isVisible={isVisible}
            fieldName={fieldName}
            dmdcTargetField={dmdcTargetField}
            childFieldName={childFieldName}
            options={options}
            value={(value as string) ?? ''}
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
          />
        );
      } else return <>Something bad happened: Please contact your administrator</>;
    case FIELD_TYPES.CHECKBOX: {
      let defaultValue = false;
      if (value) defaultValue = true; // we need to validate all falsy values not just 'false'
      return (
        <CheckBox
          label={label}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isVisible={isVisible}
          fieldName={fieldName}
          dmdcTargetField={dmdcTargetField}
          onBlur={handleFieldBlur}
          value={defaultValue as boolean}
          errorMessage={validationErrorMessage}
          assetId={assetId}
          tabName={tabName}
          titleName={titleName}
          onChange={handleFieldChange}
        />
      );
    }
    case FIELD_TYPES.LABEL: {
      return (
        <Label
          fieldName={fieldName}
          value={value as string}
          isVisible={isVisible}
          isIdValue={isIdValue}
        />
      );
    }
    default:
      return <>{fieldType}</>;
  }
};

export default observer(DynamicFieldItem);
