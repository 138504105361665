import { Assets } from 'base/models/Asset';
import { GroupType, UserDropdown } from 'base/models/DashboardProps';
import { ResponseCounts } from 'base/stores/assetsStore';
import { resolvePromise } from '../utils/resolveAPICall';
import api from './config';

export const getAssetsGrouped = async (
  statusFilter: number,
  groupBy: GroupType,
  emailFilter: string
) => {
  let groupByString = '';
  switch (groupBy) {
    case GroupType.TYPE_TITLE:
      groupByString = 'assetType-Title';
      break;
    case GroupType.TITLE:
      groupByString = 'title';
      break;
  }

  const response = await resolvePromise(
    api.get<Assets[]>(`assets?status=${statusFilter}&groupBy=${groupByString}&user=${emailFilter}`)
  );

  return response;
};

export const getAssetStatusCount = async (userSelected: string) => {
  return await resolvePromise(api.get<ResponseCounts>(`assets/status/count?user=${userSelected}`));
};

export const getUsers = async () => {
  return await resolvePromise(api.get<UserDropdown[]>('assets/users'));
};

export const getAssetsHoldback = async (userSelected: string, groupBy: GroupType) => {
  let groupByString = '';
  switch (groupBy) {
    case GroupType.TYPE_TITLE:
      groupByString = 'assetType-Title';
      break;
    case GroupType.TITLE:
      groupByString = 'title';
      break;
  }
  return await resolvePromise(
    api.get(`assets/holdback?user=${userSelected}&groupBy=${groupByString}`)
  );
};
