import { Assets } from 'base/models/Asset';
import { Template } from 'base/models/Template';

export const getTemplateIdsFromAssets = (
  assets: Assets,
  fetchTemplate: (templateId: string) => Promise<any>
) => {
  const templateIds = assets.reduce((templateIds, asset) => {
    if (asset && asset.templateId) templateIds.push(asset.templateId);
    return templateIds;
  }, [] as string[]);
  const distinctTemplateIds = new Set([...templateIds]);

  const templates = new Map<string, Template>();
  distinctTemplateIds.forEach(async (templateId) => {
    const template = await fetchTemplate(templateId);

    templates.set(templateId, template);
  });
  return templates;
};
