import React from 'react';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { DateFormat } from 'components/Dashboard/DateFormat';
import { dateTypes } from 'base/models/DashboardProps';
import { TitleRowFormat } from 'components/Dashboard/TitleRowFormat';

export const ColumnsType = (): GridColDef[] => {
  const ColumnsType: GridColDef[] = [
    {
      field: 'packageId',
      headerName: 'Package Id',
      type: 'string',
      flex: 0.5,
    },
    {
      field: 'language',
      headerName: 'Language',
      type: 'string',
      flex: 0.5,
    },
    {
      field: 'assetId',
      headerName: 'Asset ID',
      type: 'string',
      flex: 0.15,
    },
    {
      field: 'assetType',
      headerName: 'Asset Type',
      type: 'string',
      flex: 0.25,
      renderCell: ({ row }: GridCellParams) => <TitleRowFormat typeTitle={row.assetType} />,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      flex: 0.25,
    },
    {
      field: 'ingestDate',
      headerName: 'Ingest Date',
      type: 'date',
      flex: 0.25,
      renderCell: ({ row }: GridCellParams) => (
        <>
          {row.ingestDate && (
            <DateFormat
              date={row.ingestDate}
              format={dateTypes.DATE_TYPE_STANDARD}
              dateFormat="DD-MMM-YYYY"
            />
          )}
        </>
      ),
    },
    {
      field: 'ingestedBy',
      headerName: 'Ingested By',
      type: 'string',
      flex: 0.3,
    },
    {
      field: 'versionNumber',
      headerName: 'Version',
      type: 'number',
      width: 140,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'filename',
      headerName: 'Filename',
      type: 'string',
      flex: 0.3,
    },
  ];

  return ColumnsType;
};

export const ColumnsTitle: GridColDef[] = [
  {
    field: 'packageId',
    headerName: 'Package Id',
    type: 'string',
    flex: 0.5,
  },
  {
    field: 'language',
    headerName: 'Language',
    type: 'string',
    flex: 0.2,
  },
  {
    field: 'assetId',
    headerName: 'Asset ID',
    type: 'string',
    flex: 0.5,
  },
  {
    field: 'assetType',
    headerName: 'Asset Type',
    type: 'string',
    flex: 0.3,
    renderCell: ({ row }: GridCellParams) => <TitleRowFormat typeTitle={row.assetType} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    flex: 0.3,
  },
  {
    field: 'ingestDate',
    headerName: 'Ingest Date',
    type: 'date',
    flex: 0.27,
    renderCell: ({ row }: GridCellParams) => (
      <>
        {row.ingestDate && (
          <DateFormat
            date={row.ingestDate}
            format={dateTypes.DATE_TYPE_STANDARD}
            dateFormat="DD-MMM-YYYY"
          />
        )}
      </>
    ),
  },
  {
    field: 'ingestedBy',
    headerName: 'Ingested By',
    type: 'string',
    flex: 0.28,
  },
  {
    field: 'versionNumber',
    headerName: 'Version',
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    flex: 0.25,
  },
  {
    field: 'filename',
    headerName: 'Filename',
    type: 'string',
    flex: 0.6,
  },
];
