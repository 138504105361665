import React, { useState } from 'react';
import { AssetGrid } from './AssetGrid';
import { DivContainer, useStyles } from './Dashboard.styles';
import { DashboardMenu } from './Menu';
import { GroupType } from 'base/models/DashboardProps';
import { DashboardFilter } from 'components/DashboardFilter';
import Grid from '@material-ui/core/Grid';
import { useEffect } from 'react';
import { AssetStatus } from 'base/enums/AssetStatus';
import { useRootStoreContext } from 'base/stores/rootStore';
import localStorageAccess, {
  LocalCurrentFilter,
  LocalStorageKeys,
  retrieveLocalStatusFilter,
} from 'base/utils/localStorageAccess';
import { useQuery } from 'react-query';
import { getTemplates } from 'base/api/assetType';
import QUERY_IDENTIFIERS from 'base/constants/reactQueryIdentifiers';

const Dashboard: React.FC = () => {
  const [statusFilter, setStatusFilter] = useState(AssetStatus.Draft);
  const [dataGroup, setDataGroup] = useState(GroupType.TYPE_TITLE);
  const [isDisabled, setIsDisabled] = useState(false);
  const classes = useStyles();

  const {
    setStatusFilterInStore,
    setGroupTypeInStore,
    getUserFilterInStore: userFilter,
  } = useRootStoreContext().dashboardStore;

  const { clearMap: clearTempPreviews } = useRootStoreContext().previewStore;
  const { cleanAllAssetsFromEditStore } = useRootStoreContext().editStore;
  const { removeAssetsFromStore } = useRootStoreContext().ingestionStore;

  const updateStatusFilter = (status: AssetStatus) => {
    setStatusFilterInStore(status);
    setStatusFilter(status);
    const statusFilterSelected: LocalCurrentFilter = { currentFilter: status };
    localStorageAccess.setToLocalStorage(LocalStorageKeys.currentFilter, statusFilterSelected);
  };

  const updateGroupFilter = (group: GroupType) => {
    setGroupTypeInStore(group);
    setDataGroup(group);
  };

  useQuery([QUERY_IDENTIFIERS.ASSET_TEMPLATES], getTemplates, {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (userFilter.email) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userFilter.fullname]);

  useEffect(() => {
    const currentFilter = retrieveLocalStatusFilter();
    if (currentFilter?.currentFilter) {
      setStatusFilter(currentFilter.currentFilter);
    }

    clearTempPreviews();
    cleanAllAssetsFromEditStore();
    removeAssetsFromStore();
  }, []);

  return (
    <DivContainer>
      <Grid container spacing={3}>
        <Grid item container xs={12} md lg={3} direction="column" justify="space-between">
          <DashboardFilter
            updateGroupFilter={updateGroupFilter}
            selectedGroup={dataGroup}
            isDisabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md lg={9} className={classes.root}>
          <DashboardMenu updateStatus={updateStatusFilter} />
        </Grid>
      </Grid>
      <AssetGrid
        className={classes.dashboardGrid}
        statusFilter={statusFilter}
        columnType={dataGroup}
      />
    </DivContainer>
  );
};

export default Dashboard;
