import api from './config';
import { resolvePromise } from '../utils/resolveAPICall';
import { AuthenticationResponse } from 'base/models/UserInfo';

import { ROUTES } from 'base/constants/route';

const redirectUrl = `${window.location.protocol}//${window.location.host}/${ROUTES.LOGIN_CALLBACK}`;

export const postSessionKey = async (sessionKey: string) => {
  const response = await resolvePromise(
    api.post<AuthenticationResponse>(`authenticate`, { sessionKey, redirectUrl })
  );

  return response;
};

export const dmdcAuthenticate = async (code: string) => {
  const response = await resolvePromise(
    api.post<AuthenticationResponse>(`dmdcAuthenticate`, { code, redirectUrl })
  );

  return response;
};

export const getUserInfo = async (sessionKey: string) => {
  const response = await resolvePromise(
    api.post<AuthenticationResponse>(`GetUserInfo`, { sessionKey, redirectUrl })
  );

  return response;
};
