import api from './config';
import { resolvePromise } from 'base/utils/resolveAPICall';
import { ContextSwitcherList } from 'base/models/ContextSwitcher';

export const getContextSwitcherByUser = async (pageName: string) => {
  const response = await resolvePromise(
    api.get<ContextSwitcherList>(`contextSwitcher?pageName=${pageName}`)
  );
  return response;
};
