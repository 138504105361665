export const sessionStorageKeys = {
  requestToken: 'requestToken',
  returnUrl: 'returnUrl',
};

interface SessionStorageAccess {
  getValue: (key: string) => string | null;
  setValue: (key: string, value: string) => void;
  removeValue: (key: string) => void;
}

const getValue = (key: string): string | null => sessionStorage.getItem(key);

const setValue = (key: string, value: string) => sessionStorage.setItem(key, value);

const removeValue = (key: string) => sessionStorage.removeItem(key);

const sessionStorageAccess: SessionStorageAccess = {
  getValue,
  setValue,
  removeValue,
};

export default sessionStorageAccess;
