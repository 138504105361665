import React, { Fragment, useEffect } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useRootStoreContext } from 'base/stores/rootStore';

export interface CheckBoxProps {
  errorMessage?: string;

  label: string;
  isDisabled: boolean;
  isRequired: boolean;
  isVisible: boolean;
  fieldName: string;
  dmdcTargetField: string;
  onChange?: (name: string, value: string | boolean) => void;
  onBlur?: (name: string, value: any) => void;
  value?: string | boolean;
  assetId: string;
  tabName: string;
  titleName: string;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  errorMessage,
  isRequired,
  isDisabled,
  isVisible,
  fieldName,
  dmdcTargetField,
  value = false,
  onChange,
  onBlur,
  assetId,
  tabName,
  titleName,
}: CheckBoxProps) => {
  const { setAssetFieldFromTabInStore } = useRootStoreContext().editStore;
  const [isChecked, setIsChecked] = React.useState(false);
  const elementTypeFieldName = 'elementType';
  const screenerValue = { id: 101390, value: 'Screener' };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parentCheckboxName = 'isParent';
    if (event.target.name === parentCheckboxName && event.target.checked) {
      setAssetFieldFromTabInStore(elementTypeFieldName, screenerValue, tabName, titleName, assetId);
    }
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(fieldName, event.target.checked);
    }
  };

  const handleFieldBlur = (_: React.FocusEvent<HTMLButtonElement>) => {
    if (onBlur) {
      onBlur(fieldName, isChecked);
    }
  };

  useEffect(() => {
    if (typeof value === 'boolean') {
      setIsChecked(value);
    }
  }, [value]);

  return (
    <Fragment>
      {isVisible && (
        <FormControl variant="standard" style={{ display: 'flex' }}>
          <FormControlLabel
            label=""
            disabled={isDisabled}
            style={{ justifyContent: 'center' }}
            control={
              <Checkbox
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={isChecked}
                name={fieldName}
                disabled={isDisabled}
                hidden={!isVisible}
                onBlur={handleFieldBlur}
                id={`checkbox-field-${dmdcTargetField}`}
                onChange={handleChange}
                required={isRequired}
              />
            }
          />
        </FormControl>
      )}
    </Fragment>
  );
};

export default CheckBox;
