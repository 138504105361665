import { AssetStatus } from 'base/enums/AssetStatus';
import { statusColors } from 'styles/colors';

export const assetStatusColorMap = {
  [AssetStatus.Draft]: {
    primary: statusColors.DRAFT,
    contrast: '#fff',
  },
  [AssetStatus.InProgress]: {
    primary: statusColors.IN_PROGRESS,
    contrast: '#fff',
  },
  [AssetStatus.NotPublished]: {
    primary: statusColors.NOT_PUBLISHED,
    contrast: '#fff',
  },
  [AssetStatus.Published]: {
    primary: statusColors.PUBLISHED,
    contrast: '#fff',
  },
  [AssetStatus.Failed]: {
    primary: statusColors.FAILED,
    contrast: '#fff',
  },
  [AssetStatus.Holdback]: {
    primary: statusColors.HOLDBACK,
    contrast: '#fff',
  },
};
