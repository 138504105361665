import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import QUERY_IDENTIFIERS from 'base/constants/reactQueryIdentifiers';
import { AssetStatus } from 'base/enums/AssetStatus';
import { StatusCountType } from 'base/models/DashboardProps';
import { useRootStoreContext } from 'base/stores/rootStore';
import { UpdateCounter } from 'base/utils/dasboardCounter';
import { retrieveLocalStatusFilter } from 'base/utils/localStorageAccess';
import { Loading } from 'components/Dashboard/Loading';
import { assetStatusText } from '../../../base/models/DashboardProps';
import {
  DivContainerLoading,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from './DashboardMenu.styles';
import { StatusButtonFilter } from './StatusButtonFilter';

type DashboardMenuProps = {
  updateStatus: (arg: AssetStatus) => void;
  statusCountInit?: StatusCountType;
};

const DashboardMenu: React.FC<DashboardMenuProps> = ({ updateStatus }: DashboardMenuProps) => {
  const [selected, setSelected] = useState(AssetStatus.Draft);
  const {
    dashboardStore: { getUserFilterInStore: userFilter },
    assetsStore: { setStatusTotal, getStatusCountInStore },
  } = useRootStoreContext();
  const { isLoading: isLoadingMenu, data: statusTotal } = useQuery(
    [QUERY_IDENTIFIERS.DASHBOARD_COUNTS, userFilter],
    () => UpdateCounter(userFilter.email)
  );

  const queryClient = useQueryClient();

  const handleChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: React.SetStateAction<string>
  ) => {
    if (newValue != null) {
      updateStatus(newValue as unknown as AssetStatus);
      setSelected(newValue as unknown as AssetStatus);
    }
    queryClient.invalidateQueries(QUERY_IDENTIFIERS.DASHBOARD_COUNTS);
  };

  useEffect(() => {
    if (statusTotal) {
      setStatusTotal(statusTotal);
    }
  }, [statusTotal]);

  useEffect(() => {
    const localStatusFilter = retrieveLocalStatusFilter();
    if (localStatusFilter?.currentFilter) {
      setSelected(localStatusFilter.currentFilter);
    }
  }, []);

  return (
    <>
      {isLoadingMenu ? (
        <DivContainerLoading>
          <Loading />
        </DivContainerLoading>
      ) : (
        <StyledToggleButtonGroup
          value={selected}
          exclusive
          onChange={handleChange}
          aria-label="text alignment"
        >
          <StyledToggleButton value={AssetStatus.Draft}>
            <StatusButtonFilter
              totalAssets={getStatusCountInStore.Draft}
              status={assetStatusText.DRAFT}
            />
          </StyledToggleButton>
          <StyledToggleButton value={AssetStatus.InProgress}>
            <StatusButtonFilter
              totalAssets={getStatusCountInStore.InProgress}
              status={assetStatusText.IN_PROGRESS}
            />
          </StyledToggleButton>
          <StyledToggleButton value={AssetStatus.NotPublished}>
            <StatusButtonFilter
              totalAssets={getStatusCountInStore.NotPublished}
              status={assetStatusText.NOT_PUBLISHED}
            />
          </StyledToggleButton>
          <StyledToggleButton value={AssetStatus.Published}>
            <StatusButtonFilter
              totalAssets={getStatusCountInStore.Published}
              status={assetStatusText.PUBLISHED}
            />
          </StyledToggleButton>
          <StyledToggleButton value={AssetStatus.Failed}>
            <StatusButtonFilter
              totalAssets={getStatusCountInStore.Failed}
              status={assetStatusText.FAILED}
            />
          </StyledToggleButton>
          <StyledToggleButton value={AssetStatus.Holdback}>
            <StatusButtonFilter
              totalAssets={getStatusCountInStore.Holdback}
              status={assetStatusText.HOLDBACK}
            />
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      )}
    </>
  );
};

export default observer(DashboardMenu);
