import React, { useState, ChangeEvent, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { Tab, Tabs } from '@material-ui/core';
import { useLocation } from 'react-router';

import { EditAssetUnassigned } from './EditAssetUnassigned';
import { TabContainer } from 'components/EditAsset/TabContainer';
import { useRootStoreContext } from 'base/stores/rootStore';
import { Tab as TabInStore } from 'base/models/Asset';
import { EditAssetTabList } from './EditAssetTabList';
import { EditAssetButtons } from './EditAssetButtons';
import { useStyles } from './EditAsset.styles';

interface stateType {
  assetGroupName?: string;
}

interface EditAssetProps {
  externalAssetGroupName?: string;
}

const initialTabIndex = 0;
const EditAsset = ({ externalAssetGroupName = '' }: EditAssetProps) => {
  const classes = useStyles();
  const {
    editStore: { tabsInEditStore, setCurrentTab },
    ingestionStore: { assetsInStore },
  } = useRootStoreContext();
  const [tabValue, setTabValue] = useState(initialTabIndex);
  const [tabName, setTabName] = useState('');

  const { state } = useLocation<stateType>();

  useEffect(() => {
    setCurrentTab(initialTabIndex);
  }, []);

  const handleChangeTab = (_: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setCurrentTab(newValue - 1);
  };

  const countNumberOfAssetsInTab = (tab: TabInStore) => {
    let totalAssets = 0;
    tab.titles.forEach((title) => (totalAssets += title.assets.length));

    return totalAssets;
  };

  const countNumberOfUnassignedAssets = () => {
    return assetsInStore.length;
  };

  const handleGetTabName = (
    _: React.MouseEventHandler<HTMLAnchorElement> | undefined,
    tabName: string
  ) => {
    setTabName(tabName);
  };

  const setUnassignedTab = () => {
    if (tabsInEditStore.length === 0) {
      setTabName('');
      setTabValue(0);
    }
  };

  useEffect(() => {
    /**
     * If some tab got deleted, we move to the first tab to force
     * select another tab and get it's name again for EditAssetButtons
     */
    const assetGroupName = state?.assetGroupName;
    if ((assetGroupName || externalAssetGroupName) && tabsInEditStore.length > 0) {
      setTabValue(1);
      if (assetGroupName) setTabName(assetGroupName);
      else if (externalAssetGroupName) setTabName(externalAssetGroupName);
    } else {
      setTabValue(initialTabIndex);
    }
  }, [tabsInEditStore.length]);
  return (
    <>
      <EditAssetButtons tabName={tabName} setUnassignedTab={setUnassignedTab} />
      <div className={classes.root}>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            key="Unassigned"
            label={`Unassigned (${countNumberOfUnassignedAssets()})`}
            onClick={(e) => handleGetTabName(e, '')}
          />
          {tabsInEditStore.length > 0 &&
            tabsInEditStore.map((tab: TabInStore) => {
              const totalAssets = countNumberOfAssetsInTab(tab);
              return (
                <Tab
                  onClick={(e) => handleGetTabName(e, tab.name)}
                  label={`${tab.name} (${totalAssets})`}
                  key={tab.name}
                />
              );
            })}
        </Tabs>
      </div>
      {tabValue === 0 && (
        <TabContainer key="Unassigned">
          <EditAssetUnassigned />
        </TabContainer>
      )}
      {tabsInEditStore.length > 0 && <EditAssetTabList selectedTab={tabValue} />}
    </>
  );
};

export default observer(EditAsset);
