export enum statusColors {
  DRAFT = '#898989',
  IN_PROGRESS = '#fcb626',
  NOT_PUBLISHED = '#e987b3',
  PUBLISHED = '#26ae8f',
  FAILED = '#dc6775',
  HOLDBACK = '#9b2030',
}

export enum textContrast {
  DRAFT = '#f0f0f0',
  IN_PROGRESS = '#000000',
  NOT_PUBLISHED = '#f0f0f0',
  PUBLISHED = '#000000',
  FAILED = '#f0f0f0',
  HOLDBACK = '#f0f0f0',
}
