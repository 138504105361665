import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'stretch',
    },
    progressButton: {
      color: '#aaaaaa',
      backgroundColor: '#ffffff',
      borderColor: '#216085',
    },
    actionButton: {
      color: '#216085',
      backgroundColor: '#ffffff',
      borderColor: '#575F72',
      borderStyle: 'solid',
      borderWidth: '1px',
      minWidth: 40,
      marginLeft: 16,
      '&:disabled svg': {
        opacity: 0.5,
      },
      display: 'flex',
      alignItems: 'center',
      '& span': {
        lineHeight: '1.5',
      },
    },
    saveButton: {
      color: '#ffffff',
      backgroundColor: '#216085',
      '&:hover': {
        color: '#ffffff',
        backgroundColor: '#216085',
      },
      marginLeft: 16,
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    moreButton: {
      color: '#ffffff',
      backgroundColor: '#216085',
      '&:hover': {
        color: '#ffffff',
        backgroundColor: '#216085',
      },
      minWidth: '20px',
      padding: '0px',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      height: '100%',
    },
    spinningIcon: {
      animation: `$spin 1000ms linear infinite`,
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);
