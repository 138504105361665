import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from 'base/stores/rootStore';
import { ContextSwitcherList } from 'base/models/ContextSwitcher';

export class ContextSwitcherStore {
  rootStore: RootStore;
  constructor(rootstore: RootStore) {
    makeObservable(this);
    this.rootStore = rootstore;
  }

  @observable contextSwitcherData: ContextSwitcherList = [];

  @action setContextSwitcherData = (value: ContextSwitcherList) => {
    this.contextSwitcherData = value;
  };

  @computed get getContextSwitcherData() {
    return this.contextSwitcherData;
  }
}
