import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from './rootStore';

export default class TransferStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable uploadMap: Map<string, Object> = new Map<string, Object>();

  @action upsertUploadItem = (transferKey: string, payload: unknown) => {
    this.uploadMap.set(transferKey, {
      ...(this.uploadMap.get(transferKey) || {}),
      ...(payload || ({} as any)),
    });
  };

  @action clearUploadMap = () => {
    this.uploadMap.clear();
  };

  @computed get getUploads() {
    return this.uploadMap;
  }
}
