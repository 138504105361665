import React from 'react';
import { useStyles } from './DashboardFilter.styles';
import { GroupType } from '../../base/models/DashboardProps';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

type DashboardFilterProps = {
  updateGroupFilter: (arg: GroupType) => void;
  selectedGroup: GroupType;
  isDisabled: boolean;
};

const DashboardFilter: React.FC<DashboardFilterProps> = ({
  updateGroupFilter,
  selectedGroup,
  isDisabled,
}: DashboardFilterProps) => {
  const [value, setValue] = React.useState<GroupType>(selectedGroup);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateGroupFilter(event.target.value as GroupType);
    setValue(event.target.value as GroupType);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          className={classes.selectFilter}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          data-testid="select-option"
        >
          <MenuItem value={GroupType.TYPE_TITLE}> Title & Type </MenuItem>
          <MenuItem value={GroupType.TITLE}>Title</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default DashboardFilter;
