import React, { useEffect, useMemo, useRef, useState } from 'react';

import { TableContainer, Table, TableBody, TableSortLabel } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import { useRootStoreContext } from 'base/stores/rootStore';
import { Asset, Assets } from 'base/models/Asset';
import { updateAssets } from 'components/Upload/Upload.functions';
import { SortableHead } from './SortableHead';
import { SortableCell } from './SortableCell';

import { CellHeader, useStyles } from './EditGrid.styles';
import { Template, TemplateField } from 'base/models/Template';

import { EditGridRow } from 'components/EditAsset/EditGrid/EditGridRow';

import { SortableFields } from './SortableFields';
import { Aspera } from 'base/utils/aspera-client';
import { DraftAssetPatchAction } from 'base/enums/DraftAssetPatchAction';

interface EditGridProps {
  assets: Assets;
  titleName: string;
  tabName: string;
  template: Template | undefined;
  fields: TemplateField[];
  orderFields: number[];
  setOrderFields: (orderedFields: number[]) => void;
}

const createdDateFieldName = 'assetCreated';
const modifiedDateFieldName = 'assetModifiedDate';

export const EditGrid: React.FC<EditGridProps> = ({
  assets,
  titleName,
  tabName,
  template,
  fields,
  orderFields,
  setOrderFields,
}: EditGridProps) => {
  const { current: aspera } = useRef(new Aspera());
  aspera.init();
  const previewContainer = React.useRef(null);
  const classes = useStyles();
  const [assetId, setAssetId] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('');
  const {
    editStore: {
      pushPendingUpdate,
      removePendingUpdate,
      setAssignedAssetsCheckedInStore,
      areAllAssignedAssetsChecked,
      setAssetFieldFromTabInStore,
      getIsExternalEditing: isExternalEditing,
      isSaving,
    },
    ingestionStore: { getAssetTypeIdInTemplateStore },
  } = useRootStoreContext();

  const assetTypeId = parseInt(getAssetTypeIdInTemplateStore ?? '0');
  const [firstAsset] = assets;
  const assetGroupId = parseInt(firstAsset.assetGroup.id, 10);

  // choose presets based on selected asset type
  const selectedPresets = useMemo(
    () => template?.presets?.find((preset) => preset.assetGroupId === assetGroupId),
    [template, assetTypeId]
  );

  const onReorderEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const newOrder = [...orderFields];
      const moved = newOrder.splice(oldIndex, 1);
      newOrder.splice(newIndex, 0, moved[0]);
      setOrderFields(newOrder);
    }
  };

  const handleFieldBlur = async (fieldName: string, value: any) => {
    setAssetFieldFromTabInStore(fieldName, value, tabName, titleName, assetId);

    if (!isExternalEditing) {
      // we only update to Cosmos if not editing from DMDC
      const pendingUpdate = {
        id: uuidv4(),
        message: `Updating field: ${fieldName}`,
      };
      pushPendingUpdate(pendingUpdate);

      await updateAssets({
        action: DraftAssetPatchAction.MetadataUpdate,
        assets: assets,
      });
      setTimeout(() => removePendingUpdate(pendingUpdate), 1000);
    }
  };

  const handleCheckboxItemChange = (e: React.ChangeEvent<HTMLInputElement>, assetId: string) => {
    const checkValue = e.target.checked;
    setAssignedAssetsCheckedInStore(checkValue, tabName, titleName, assetId);
  };

  const handleCheckboxHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkValue = e.target.checked;
    setAssignedAssetsCheckedInStore(checkValue, tabName, titleName);
  };

  const handleTableCellFocus = (
    _: React.FocusEvent<HTMLTableHeaderCellElement>,
    assetId: string
  ) => {
    setAssetId(assetId);
  };

  const isSortableField = (fieldName: string) => {
    return SortableFields.find((field) => field === fieldName);
  };

  const handleSort = (fieldName: string) => {
    if (fieldName === orderBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(fieldName);
    }
  };

  useEffect(() => {
    if (orderBy !== '') {
      assets.sort((assetA, assetB) => {
        if (orderBy === createdDateFieldName) {
          return (
            (assetA.createdAt > assetB.createdAt
              ? 1
              : assetA.createdAt < assetB.createdAt
              ? -1
              : 0) * (sortOrder === 'desc' ? -1 : 1)
          );
        }
        if (orderBy === modifiedDateFieldName) {
          const modifiedA = assetA.modified?.date ?? new Date('1-1-0001');
          const modifiedB = assetB.modified?.date ?? new Date('1-1-0001');
          return (
            (modifiedA > modifiedB ? 1 : modifiedA < modifiedB ? -1 : 0) *
            (sortOrder === 'desc' ? -1 : 1)
          );
        }
        if (orderBy === 'contentType') {
          const contentTypeA = assetA.fields.contentType?.value ?? '';
          const contentTypeB = assetB.fields.contentType?.value ?? '';
          return contentTypeA.localeCompare(contentTypeB) * (sortOrder === 'desc' ? 1 : -1);
        }
        if (orderBy === 'contentSubType') {
          const subContentTypeA = assetA.fields.contentSubType?.value ?? '';
          const subContentTypeB = assetB.fields.contentSubType?.value ?? '';
          return subContentTypeA.localeCompare(subContentTypeB) * (sortOrder === 'desc' ? -1 : 1);
        }
        if (orderBy === 'language') {
          const languageA = assetA.fields.language?.value ?? '';
          const languageB = assetB.fields.language?.value ?? '';
          return languageA.localeCompare(languageB) * (sortOrder === 'desc' ? -1 : 1);
        }
        return (
          String(assetA.fields[orderBy]).localeCompare(String(assetB.fields[orderBy])) *
          (sortOrder === 'desc' ? -1 : 1)
        );
      });
    }
  }, [orderBy, sortOrder]);

  return (
    <>
      <TableContainer className={classes.scrollBar}>
        <Table stickyHeader aria-label="template-table" className={classes.gridRoot}>
          <SortableHead
            axis="x"
            onSortEnd={onReorderEnd}
            distance={5}
            handleCheckbox={handleCheckboxHeaderChange}
            areAssetsChecked={areAllAssignedAssetsChecked}
            tabName={tabName}
            titleName={titleName}
            orderByFileName={orderBy === 'fileName'}
            sortOrder={sortOrder}
            handleSort={handleSort}
          >
            {orderFields?.map((columnIndex, index) => {
              return (
                fields[columnIndex].isVisible && (
                  <SortableCell
                    index={index}
                    key={columnIndex}
                    value={
                      <CellHeader
                        align="justify"
                        key={fields[columnIndex].label}
                        style={{ cursor: 'grab' }}
                      >
                        {isSortableField(fields[columnIndex].fieldName) ? (
                          <TableSortLabel
                            active={orderBy === fields[columnIndex].fieldName}
                            direction={sortOrder}
                            onClick={() => handleSort(fields[columnIndex].fieldName)}
                          >
                            {fields[columnIndex].label}
                          </TableSortLabel>
                        ) : (
                          fields[columnIndex].label
                        )}
                      </CellHeader>
                    }
                  />
                )
              );
            })}
          </SortableHead>
          <TableBody>
            {assets.map((asset: Asset) => {
              return (
                <EditGridRow
                  key={asset.id}
                  asset={asset}
                  orderFields={orderFields}
                  fields={fields}
                  isSaving={isSaving}
                  selectedPresets={selectedPresets}
                  titleName={titleName}
                  tabName={tabName}
                  handleCheckboxItemChange={handleCheckboxItemChange}
                  handleTableCellFocus={handleTableCellFocus}
                  handleFieldBlur={handleFieldBlur}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div ref={previewContainer}></div>
    </>
  );
};

export default observer(EditGrid);
