export enum AssetGroupIds {
  EpisodicPhotos = '1',
  EventPhotos = '4',
  GalleryPhotos = '5',
  LogosAndArtwork = '6',
  ProductionStills = '12',
  Publicity = '42',
  SpecialShootPhotos = '34',
  WebAndSocialContent = '10',
  Broll = '54',
  Clips = '46',
  Featurettes = '47',
  InterviewSoundbites = '49',
  MotionGraphicPackages = '51',
  Promos = '50',
  Tags = '52',
  Trailers = '48',
  TVSpots = '53',
}
