import React, { useState, useEffect } from 'react';
import { Badge } from '@material-ui/core';
import { buildPreviewCdnUrl } from 'base/api/preview';
import { PREVIEW_DEFAULT_IMAGE } from 'base/constants/previewFiles';
import { Asset } from 'base/models/Asset';
import { CustomPreviewMenu } from 'components/Dashboard/CustomPreview';
import { useStyles } from './Preview.styles';
import { SIZES } from 'base/constants/previewFiles';
import { useRootStoreContext } from 'base/stores/rootStore';
import { AssetStatus } from 'base/enums/AssetStatus';
import { observer } from 'mobx-react-lite';
import Magnifier from './Magnifier.component';
import { previewToken } from 'base/utils/tokenHelper';

interface PreviewProps {
  isFirstPreview: boolean;
  previewStyle: string;
  asset: Asset;
  firstPreviewUrl?: string;
  hideMenu?: boolean;
}

const Preview: React.FC<PreviewProps> = ({ isFirstPreview, asset, hideMenu }: PreviewProps) => {
  const classes = useStyles();
  const [previewUrl, setPreviewUrl] = useState([PREVIEW_DEFAULT_IMAGE]);
  const [largeUrl, setLargeUrl] = useState<string | null>(null);
  const { getPreviewMaps } = useRootStoreContext().previewStore;
  const { upsertAssetPreviewUpdate } = useRootStoreContext().editStore;
  const [allowCustomPreview, setAllowCustomPreview] = useState<boolean>(false);
  const [currentAsset, setCurrentAsset] = useState<Asset | null>(asset);
  const previewContainer = React.useRef(null);

  useEffect(() => {
    setCurrentAsset(asset);
  }, [asset]);

  const token = previewToken();

  const handleImageOnLoad = (ev: React.SyntheticEvent<Element, Event>) => {
    if ((ev.target as HTMLImageElement).src !== PREVIEW_DEFAULT_IMAGE && !largeUrl) {
      const largeCdnUrl = buildPreviewCdnUrl(
        token as string,
        asset.customPreviewId ?? (asset.previewId as string),
        SIZES.LARGE
      );
      setLargeUrl(largeCdnUrl);
    }
  };

  const setupPreview = async () => {
    if (currentAsset) {
      const base64 = getPreviewMaps.get(currentAsset.assetId);
      if (base64) {
        setLargeUrl(base64.Base64Large);
        setPreviewUrl([base64.Base64Small, PREVIEW_DEFAULT_IMAGE]);
        return;
      }

      const smallCdnUrl = buildPreviewCdnUrl(
        token as string,
        currentAsset.customPreviewId ?? (currentAsset.previewId as string),
        SIZES.SMALL
      );

      const largeUrl =
        currentAsset.customPreviewId || currentAsset.previewId ? null : PREVIEW_DEFAULT_IMAGE;

      setLargeUrl(largeUrl);
      setPreviewUrl([smallCdnUrl, PREVIEW_DEFAULT_IMAGE]);
    }
  };

  const setupCustomPreview = async () => {
    const status = currentAsset?.fields.status;

    if (status !== AssetStatus.Draft) {
      setAllowCustomPreview(true && !hideMenu);
    }
  };

  useEffect(() => {
    if (allowCustomPreview && currentAsset) {
      const customPreviewId = currentAsset.customPreviewId
        ? currentAsset.customPreviewId.split('.')[0]
        : currentAsset.customPreviewId;

      const previewId = currentAsset.previewId ? currentAsset.previewId : customPreviewId;

      upsertAssetPreviewUpdate(currentAsset.assetId, previewId, customPreviewId);
    }

    setupPreview();
    setupCustomPreview();
  }, [getPreviewMaps, currentAsset]);

  return (
    <div>
      {allowCustomPreview ? (
        <Badge
          className={classes.badge}
          overlap="rectangle"
          badgeContent={
            <CustomPreviewMenu
              asset={
                currentAsset
                  ? {
                      ...currentAsset,
                      status: currentAsset.status.id as any,
                      id: currentAsset.appAssetId as any,
                    }
                  : null
              }
              updateAsset={setCurrentAsset}
            />
          }
        >
          <Magnifier
            previewUrl={previewUrl}
            largeUrl={largeUrl}
            handleImageOnLoad={handleImageOnLoad}
            previewTarget={previewContainer.current}
          />
        </Badge>
      ) : (
        <Magnifier
          previewUrl={previewUrl}
          largeUrl={largeUrl}
          handleImageOnLoad={handleImageOnLoad}
          previewTarget={previewContainer.current}
        />
      )}
    </div>
  );
};

export default observer(Preview);
