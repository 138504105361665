import api from './config';
import { AsperaPaths } from 'base/types/aspera';

export const postTransferSpec = async (asperaPaths: AsperaPaths) => {
  try {
    const response = await api.post('aspera/transfer-spec', JSON.stringify(asperaPaths), {
      headers: { 'Content-Type': 'application/json' },
    });

    return response;
  } catch (ex) {
    return null;
  }
};
