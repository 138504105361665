import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStyles } from './AddFromApp.styles';
import { useRootStoreContext } from 'base/stores/rootStore';
import { EditAsset } from 'components/EditAsset';
import { Breadcrumb } from 'components/Upload/Breadcrumb';
import { getDraftsByRequestId } from 'base/api/draft';
import { getAssetTypesByProductTypeId, getTemplateById } from 'base/api/assetType';
import { useHistory, useLocation } from 'react-router-dom';
import { Asset } from 'base/models/Asset';
import { SmallUpload } from 'components/Upload/SmallUpload';
import { AssetType, DataResponseAssetType } from 'base/models/AssetType';
import { Template } from 'base/models/Template';

const EditFromApp = () => {
  const classes = useStyles();
  const { setIngestErrorInStore, setRequestIdInStore, setExternalDataForAddInStore } =
    useRootStoreContext().editStore;
  const [externalAssetGroup] = useState('');
  const [isLoading, setLoading] = useState(true);
  const searchParameter = useLocation().search;
  const history = useHistory();

  const setError = (errorMessage: string) => {
    setIngestErrorInStore(true, errorMessage);
    setTimeout(() => {
      history.push(`/`);
    }, 3000);
  };

  const getAddAssetDependencies = async () => {
    const queryParams = new URLSearchParams(searchParameter);
    const requestId = queryParams.get('requestId');

    if (requestId) {
      const draftRequestResponse = await getDraftsByRequestId(requestId);

      if (!draftRequestResponse.error && draftRequestResponse.response) {
        const draftAsset: Asset = draftRequestResponse.response.data as unknown as Asset;

        const assetTypesResponse = await getAssetTypesByProductTypeId(
          draftAsset.title.productType?.id as number
        );

        if (assetTypesResponse) {
          const data: DataResponseAssetType = assetTypesResponse.data;
          const preSelectedAssetType = data.assetTypes.find(
            (x) => x.assetTypeId === parseInt(draftAsset.assetGroup.id)
          );

          const templateReponse = await getTemplateById(preSelectedAssetType?.templateId as string);

          if (templateReponse) {
            const preselectedTemplate: Template = templateReponse.response?.data;

            setExternalDataForAddInStore(
              draftAsset,
              requestId,
              preSelectedAssetType as AssetType,
              data.assetTypes,
              preselectedTemplate
            );
            setLoading(false);
          } else setError('Error trying to get the template');
        } else setError('Error trying to get asset types.');

        setRequestIdInStore(requestId); // for commit, save the request id
      } else setError('Error trying to get Assets.');
    } else setError('Error Request Id.');
  };

  useEffect(() => {
    getAddAssetDependencies();
  }, []);

  const renderEditSection = () => {
    if (isLoading) {
      return (
        <Grid container item xs={12} lg={12} justify="center">
          <Grid item>
            <CircularProgress size={40} thickness={4} />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <>
          <Grid item xs={12} lg={10}>
            <SmallUpload textContent="Drag and drop your files to upload" textButton="Add More" />
          </Grid>
          <Grid item xs>
            <EditAsset externalAssetGroupName={externalAssetGroup} />
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={3} justify="flex-start" alignItems="center">
          <Grid item xs={12} lg={2}>
            <Breadcrumb />
          </Grid>
          {renderEditSection()}
        </Grid>
      </Box>
    </>
  );
};

export default observer(EditFromApp);
