import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useRootStoreContext } from 'base/stores/rootStore';
import { Paper, Table, TableBody, TableContainer, TableRow } from '@material-ui/core';
import {
  CellStaticContent,
  useStyles,
  ApplySelectedContainer,
  PaperContainer,
  ApplyButton,
  ApplyButtonContainer,
  TableCellField,
} from './BulkEdit.styles';
import { DynamicFieldItem } from '../DynamicFieldItem';
import {
  Template,
  TemplateField,
  BulkEditField,
  BulkEditFields,
  statusFieldName,
} from 'base/models/Template';
import { Assets, IdValueField, InputSelectRule } from 'base/models/Asset';
import { updateAssets } from 'components/Upload/Upload.functions';
import { AssetStatus } from 'base/enums/AssetStatus';
import { DraftAssetPatchAction } from 'base/enums/DraftAssetPatchAction';

interface BulkEditProps {
  assets: Assets;
  titleName: string;
  tabName: string;
  template: Template | undefined;
  fields: TemplateField[];
  orderFields: number[];
}

const BulkEdit = ({ titleName, tabName, template, fields, orderFields, assets }: BulkEditProps) => {
  const classes = useStyles();
  const {
    assetsCheckedInTitleFromStore,
    setFieldsByBulkEditInStore,

    pushPendingUpdate,
    removePendingUpdate,

    updateAllInputSelectRule,
    getRequestId: requestId,
  } = useRootStoreContext().editStore;
  const { getAssetTypeIdInTemplateStore } = useRootStoreContext().ingestionStore;
  const [, setHasUpdateAField] = useState(false);

  const numberOfCheckedAssets = assetsCheckedInTitleFromStore(tabName, titleName);
  const assetTypeId = parseInt(getAssetTypeIdInTemplateStore ?? '0');
  const selectedPresets = useMemo(
    () => template?.presets.find((preset) => preset.assetGroupId === assetTypeId),
    [template, assetTypeId]
  );

  const [bulkFields, setBulkFields] = useState<BulkEditFields>([]);

  const handleApply = async () => {
    // Get the fields that have child Fields: i.e. content Type
    const fieldsWithChildFieldName = template?.fields.reduce(
      (prevFields: TemplateField[], field: TemplateField) => {
        if (!field.childFieldName) return [...prevFields];
        return [...prevFields, field];
      },
      [] as TemplateField[]
    );

    // We setup a rule for each field that has a child
    fieldsWithChildFieldName?.forEach((field: TemplateField) => {
      const valueContentType = bulkFields.find((f) => f.fieldName === field.fieldName);
      const newRule: InputSelectRule = {
        fieldName: field.fieldName,
        parentFieldName: field.fieldName,
        parentFieldValue: valueContentType?.value as string,
        childFieldName: field.childFieldName || '',
      };
      updateAllInputSelectRule(newRule, titleName, tabName);
    });

    // transform the updated fields from `id|value` to an object
    const newBulkFields: BulkEditField[] = transformFromStringToObjectBulkEditedFields();
    setFieldsByBulkEditInStore(tabName, titleName, newBulkFields);

    const pendingUpdate = {
      id: uuidv4(),
      message: `Bulk Edit`,
    };
    pushPendingUpdate(pendingUpdate);

    // Send to the API the changes, if we are not editing
    !requestId &&
      (await updateAssets({
        action: DraftAssetPatchAction.MetadataUpdate,
        assets: assets,
      }));
    setTimeout(() => removePendingUpdate(pendingUpdate), 1000);

    setHasUpdateAField(true);
  };

  const transformFromStringToObjectBulkEditedFields = () => {
    const transformedBulkFields: BulkEditField[] = [];

    template?.fields.forEach((templateField) => {
      // iterate over the fields of the template
      // look for the field that we have edited
      const field = bulkFields.find((bf) => bf.fieldName === templateField.fieldName);
      if (field) {
        // if we find it...
        if (templateField.isIdValue) {
          // check if it's isIdValue
          const splitedIdValue = field?.value.split('|');
          const [id, value] = splitedIdValue;
          const idValueField: IdValueField = {
            id: parseInt(id),
            value,
          };
          const newField: BulkEditField = {
            fieldName: field?.fieldName || '',
            value: idValueField,
          };
          transformedBulkFields.push(newField);
        } else {
          // if not we just push the current field
          transformedBulkFields.push(field);
        }
      }
    });
    return transformedBulkFields;
  };

  const handleFieldChange = (fieldName: string, value: any) => {
    // Create the bulkField based on fieldName and value
    const newField: BulkEditField = {
      fieldName,
      value,
    };

    // Update our updated bulkFields
    const hasRule = bulkFields.some((rule) => rule.fieldName === newField.fieldName);
    if (!hasRule) {
      setBulkFields((prev) => [...prev, newField]);
    } else {
      setBulkFields((prev) => {
        return prev.map((rule) => {
          if (newField.fieldName === rule.fieldName) {
            return {
              ...newField,
            };
          } else return rule;
        });
      });
    }
  };

  return (
    <PaperContainer>
      <TableContainer component={Paper} className={classes.scrollBar} elevation={0}>
        <Table aria-label="bulk-edit-table">
          <TableBody>
            <TableRow>
              <CellStaticContent>
                <ApplySelectedContainer>
                  Apply to selected {`(${numberOfCheckedAssets})`}
                </ApplySelectedContainer>
              </CellStaticContent>
              {orderFields.map((columnIndex) => {
                const {
                  fieldName,
                  isIdValue,
                  isDisabled,
                  isVisible,
                  fieldType,
                  label,
                  isRequired,
                  options,
                  childFieldName,
                  validationErrorMessage,
                  dmdcTargetField,
                } = fields[columnIndex];
                const [firstAsset] = assets;

                const isStatusFieldDisabled =
                  fieldName === statusFieldName &&
                  firstAsset.status.id === AssetStatus.Draft &&
                  firstAsset.fields.status === AssetStatus.Draft; // asset.fields.status is comming undefined

                return (
                  isVisible && (
                    <TableCellField
                      key={fieldName}
                      style={{ minWidth: 150, padding: '0 16px 6px' }}
                      variant="body"
                    >
                      <DynamicFieldItem
                        key={fieldName}
                        fieldType={fieldType}
                        fieldName={fieldName}
                        label={label}
                        isRequired={isRequired}
                        isVisible={isVisible}
                        isDisabled={isDisabled || isStatusFieldDisabled}
                        isIdValue={isIdValue}
                        options={options}
                        childFieldName={childFieldName}
                        validationErrorMessage={validationErrorMessage}
                        dmdcTargetField={dmdcTargetField}
                        presets={selectedPresets}
                        value={''}
                        onFieldChange={handleFieldChange}
                        isBulkEdit
                      ></DynamicFieldItem>
                    </TableCellField>
                  )
                );
              })}
              <CellStaticContent style={{ right: 0 }}>
                <ApplyButtonContainer>
                  <ApplyButton onClick={handleApply}>Apply</ApplyButton>
                </ApplyButtonContainer>
              </CellStaticContent>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PaperContainer>
  );
};

export default BulkEdit;
