import { makeStyles, createStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    buttonStyle: {
      textDecoration: 'none',
      backgroundColor: '#fff',
      border: '1px solid #c7c7c7',
      color: '#000',
      padding: '8px 16px',
      textAlign: 'center',
      display: 'flex',
      fontWeight: 'bold',
      fontSize: '1rem',
      margin: '4px 4px',
      transitionDuration: '0.4s',
      cursor: 'pointer',
      borderRadius: '10px',
      height: '2.625rem',
      alignItems: 'center',
    },
    smallButtonStyle: {
      textDecoration: 'none',
      backgroundColor: '#fff',
      border: '1px solid #c7c7c7',
      color: '#000',
      padding: '8px 4px',
      textAlign: 'center',
      display: 'flex',
      fontWeight: 'bold',
      fontSize: '0.95rem',
      margin: '4px 4px',
      transitionDuration: '0.4s',
      cursor: 'pointer',
      borderRadius: '10px',
      height: '2.625rem',
      alignItems: 'center',
    },
    buttonGrid: {
      marginTop: '-20px',
      zIndex: 1000,
      position: 'sticky',
      top: '70px',
      display: 'flex',
      alignItems: 'stretch',
    },
    widgetContainer: {
      paddingTop: 24,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export const Box = styled.div`
  display: flex;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  height: '2.625rem';
  top: 0.75rem;
`;
