// import { injectStores } from '@mobx-devtools/tools';
import { createContext, useContext } from 'react';

import { AssetStore } from './assetsStore';
import { ContextSwitcherStore } from './contextSwitcherStore';
import { DashboardStore } from './dashboardStore';
import EditStore from './editStore';
import { GroupingStore } from './groupingStore';
import IngestionStore from './ingestionStore';
import { PreviewStore } from './previewStore';
import { SettingsStore } from './settingsStore';
import TransferStore from './transferStore';

export class RootStore {
  ingestionStore: IngestionStore;
  editStore: EditStore;
  settingsStore: SettingsStore;
  dashboardStore: DashboardStore;
  previewStore: PreviewStore;
  groupingStore: GroupingStore;
  transferStore: TransferStore;
  contextSwitcherStore: ContextSwitcherStore;
  assetsStore: AssetStore;

  constructor() {
    this.ingestionStore = new IngestionStore(this);
    this.editStore = new EditStore(this);
    this.settingsStore = new SettingsStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.previewStore = new PreviewStore(this);
    this.groupingStore = new GroupingStore(this);
    this.transferStore = new TransferStore(this);
    this.contextSwitcherStore = new ContextSwitcherStore(this);
    this.assetsStore = new AssetStore(this);
  }
}

export const store = new RootStore();
export const RootStoreContext = createContext<RootStore>(store);

// injectStores({...store});

export const useRootStoreContext = () => {
  return useContext(RootStoreContext);
};
