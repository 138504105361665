import React from 'react';

import { TypographyVariant } from '@material-ui/core';
import { DivTitleContainer } from './Title.styles';

export interface TitleProps {
  title: string;
  variant: TypographyVariant | undefined;
}

const Title: React.FC<TitleProps> = ({ title, variant }: TitleProps) => {
  return <DivTitleContainer data-testid="div-title-container">{title}</DivTitleContainer>;
};

export default Title;
