import React, { useState, useEffect, useMemo } from 'react';

import { Button, Snackbar, Tooltip, Menu, MenuItem } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useStyles } from './EditAssetButtons.styles';
import { useRootStoreContext } from 'base/stores/rootStore';
import { Asset, Assets, UserState } from 'base/models/Asset';
import { commitDraft, deleteDraft, getDraftsFromAssets } from 'base/api/draft';
import { ReplacementModal } from './ReplacementModal';
import { postAssetDeleteRequest, putAssets } from 'base/api/asset';
import SvgButton, { Buttons as SvgButtonPresets } from './SvgButton';
import { DeleteModal } from 'components/shared/DeleteModal';
import AssetUploadProgress from './AssetUploadProgress/AssetUploadProgress';
import { AssetStatus, ReplacementStatusEnum } from 'base/enums/AssetStatus';
import { AsperaIngestStatus } from 'base/enums/AssetStatus';
import { AssetDuplicateDialog } from 'components/AssetDuplicate';
import { DraftAssetPatchAction } from 'base/enums/DraftAssetPatchAction';

interface EditAssetButtonsProps {
  tabName: string;
  setUnassignedTab: () => void;
}

interface alert {
  severity: Color;
  message: string;
}

const vertical = 'top';
const horizontal = 'right';

const EditAssetButtons: React.FC<EditAssetButtonsProps> = ({
  tabName,
  setUnassignedTab,
}: EditAssetButtonsProps) => {
  const classes = useStyles();
  const {
    getCheckedAssetsAndTitlesByTabFromStore,
    updateTitlesInTabFromStore,
    setIngestErrorInStore,
    tabsInEditStore,
    getIsAnyUpdatePending,
    pushPendingUpdate,
    removePendingUpdate,
    areRequiredFieldsFilled,

    getIsExternalEditing: isExternalEditing,
    getIsExternalAdding: isExternalAdding,
    getIsAnyTabInEditStore: isAnyTabInEditStore,
    setIsExternalAddingInStore,
    setIsExternalEditingInStore,
    getRequestId: requestId,
    setRequestIdInStore,
    setTabsAsEmptyInEditStore,
    assetsCheckedInTabFromStore,
    removeAssetsFromEditStore,
    setIsSaving,
    getAssetsInEditStore,
    getAssetPreviewUpdates,
    cleanAssetPreviewUpdates,
  } = useRootStoreContext().editStore;
  const {
    assetsCheckedInStore,
    deleteJobFromStore,
    removeAssetByIdFromStore,
    getIngestionAssetsInProgress,
    restoreAssetsToStore,
    assets,
  } = useRootStoreContext().ingestionStore;

  const [isReplacementModalOpen, setIsReplacementModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<alert>();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isCommitingToMAM, setIsCommitingToMAM] = useState(false);
  const [isUnassigningAssets, setIsUnassigningAssets] = useState(false);
  const [isWaitingForUploads, setIsWaitingForUploads] = useState(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isSaveAndStay, setIsSaveAndStay] = useState(false);
  const [isDraft, setIsDraft] = useState(true);
  const [replacementAsset, setReplacementAsset] = useState<Asset | null>(null);
  const [checkedAssetsInTab, setCheckedAssetsInTab] = useState<Asset[]>([]);

  useEffect(() => {
    const firstAsset = getAssetsInEditStore[0];
    if (firstAsset) {
      const checkDraft =
        (assets.length > 0 ||
          [AssetStatus.Draft, AssetStatus.Failed].includes(firstAsset.status.id)) &&
        !isExternalEditing;
      setIsDraft(checkDraft);
    }
  }, [getAssetsInEditStore, assets]);

  useEffect(() => {
    const { checkedAssets } = getCheckedAssetsAndTitlesByTabFromStore(tabName);
    setCheckedAssetsInTab([...checkedAssets]);
  }, [openCopyDialog]);

  const history = useHistory();
  const isReplaceDisabled = useMemo(() => {
    const { checkedAssets } = getCheckedAssetsAndTitlesByTabFromStore(tabName);

    if (checkedAssets.length === 1) {
      const [asset] = checkedAssets;
      if (
        asset.fields.status &&
        asset.replacementStatus?.id !== ReplacementStatusEnum.InProgress &&
        [AssetStatus.NotPublished, AssetStatus.Published].includes(asset.fields.status)
      )
        return false;
      return true;
    }
    return true;
  }, [tabName, getAssetsInEditStore]);
  const numberOfCheckedAssets = tabName
    ? assetsCheckedInTabFromStore(tabName)
    : assetsCheckedInStore.length;

  const handleClickCloseReplacementModal = () => setIsReplacementModalOpen(false);

  const handleClickOpenReplacementModal = () => {
    setIsReplacementModalOpen(true);
    const { checkedAssets } = getCheckedAssetsAndTitlesByTabFromStore(tabName);
    if (checkedAssets.length === 1) {
      const [firstAsset] = checkedAssets;
      setReplacementAsset(firstAsset);
    }
  };

  const deleteUnassignedDraft = async (asset: Asset) => {
    const response = await deleteDraft(asset.id);
    if (!response.error) {
      removeAssetByIdFromStore(asset.id);
    }
  };

  const openMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowMore(true);
  };

  const deleteAssignedDraft = async (asset: Asset) => {
    // Update the Titles in the store (without removed assets) until we know assets were removed from S3
    const response = await deleteDraft(asset.id);
    if (response.error) {
      setIngestErrorInStore(true, 'Asset could not be deleted');
    }
  };

  const handleDeleteAssets = async () => {
    setIsDeleting(true);
    if (!isExternalEditing) {
      if (tabName) {
        // Get The checked assets in the tab
        const { checkedAssets, titlesWithoutRemovedAssets } =
          getCheckedAssetsAndTitlesByTabFromStore(tabName);

        const assetsToDelete = checkedAssets.length;
        let temporalTitles = [...titlesWithoutRemovedAssets];
        const promises = checkedAssets.map((checkedAsset) => deleteAssignedDraft(checkedAsset));
        await Promise.all(promises);
        setOpen(true);
        setAlertMessage({
          severity: 'success',
          message: `${assetsToDelete} draft asset(s) deleted`,
        });
        setTimeout(() => {
          checkedAssets.forEach((asset) => {
            const { hasTitleAnyAssetYet, titlesWithoutAssets } = updateTitlesInTabFromStore(
              tabName,
              temporalTitles,
              asset
            );
            temporalTitles = titlesWithoutAssets;
            if (!hasTitleAnyAssetYet) {
              // Delete the job
              deleteJobFromStore();
            }
          });
        }, 2000);
      } else {
        const checkedAssets = assetsCheckedInStore;
        const promises = checkedAssets.map((checkedAsset) => deleteUnassignedDraft(checkedAsset));
        await Promise.all(promises);
        setOpen(true);
        setAlertMessage({
          severity: 'success',
          message: `${checkedAssets.length} draft asset(s) deleted`,
        });
      }
    } else {
      const { checkedAssets } = getCheckedAssetsAndTitlesByTabFromStore(tabName);
      const response = await postAssetDeleteRequest(
        checkedAssets.reduce((previous, current) => [...previous, current.assetId], [] as string[])
      );
      if (response.error) {
        setAlertMessage({ severity: 'error', message: 'Error on delete' });
      } else {
        setOpen(true);
        setAlertMessage({
          severity: 'success',
          message: `${checkedAssets.length} Assets marked as deleted`,
        });
        setTimeout(() => {
          const tabCount = removeAssetsFromEditStore(checkedAssets);
          if (tabCount === 0) {
            history.push('/');
          }
        }, 2000);
      }
    }
    setIsDeleting(false);
  };

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const cleanupStoreValues = () => {
    if (isExternalEditing) setIsExternalEditingInStore(false);
    if (requestId) setRequestIdInStore(null);
    if (isExternalAdding) setIsExternalAddingInStore(false);
  };

  const handleClickOnSave = (saveAndStay: boolean) => {
    setIsSaveAndStay(saveAndStay);
    if (!openSaveModal && getIngestionAssetsInProgress !== 0) {
      setOpenSaveModal(true);
    } else {
      if (saveAndStay) {
        handleSaveAndStay();
        setShowMore(false);
      } else {
        handleSave();
      }
    }
  };

  const saveAssets = async () => {
    // This timeout is due to OnBlur is being executed after this function (onMouseDown)
    // and is not sending the last changed field to the MAM
    // TODO: refactor for onBlur and onMouseDown is needed to get something more efficient
    setTimeout(async () => {
      setIsSaving(true);
      setIsSaveDisabled(true);

      let assets = tabsInEditStore.reduce((prevAssets, actualTab) => {
        const tempAssets = actualTab.titles.reduce((prevAssets, actualTitle) => {
          return [...prevAssets, ...actualTitle.assets];
        }, [] as Assets);

        return [...prevAssets, ...tempAssets];
      }, [] as Assets);

      assets = updateModifiedDate(assets);
      assets = updateStatusToAsperaIngest(assets);
      assets = updateAssetPreviews(assets);

      setIsCommitingToMAM(true);
      const response = await commitDraft({
        requestId: isExternalEditing ? requestId : null,
        assets,
      });
      setIsCommitingToMAM(false);

      if (response) {
        setOpen(true);
        setAlertMessage(
          response.response?.status === 200
            ? { severity: 'success', message: 'Save successful.' }
            : { severity: 'error', message: 'Error on save' }
        );
      }
      if (response.response?.status === 200) {
        setIsSaveDisabled(true);
        const saveToMamUpdate = {
          id: uuidv4(),
          message: 'Saving to MAM',
        };
        pushPendingUpdate(saveToMamUpdate); // this update is for disabling Save To Wonderland Button
        removePendingUpdate(saveToMamUpdate);
      }
      setIsSaving(false);
      setIsSaveDisabled(false);
    }, 100);
  };

  const handleSave = async () => {
    await saveAssets();
    setTimeout(() => {
      cleanupStoreValues();
      setTabsAsEmptyInEditStore();
      cleanAssetPreviewUpdates();
      history.push('/');
    }, 2000);
  };

  const handleSaveAndStay = async () => {
    await saveAssets();
    if (requestId) {
      const assetIds = getAssetsInEditStore.map((asset) => asset.assetId);
      const { response } = await getDraftsFromAssets(assetIds);
      const payload = response?.data;
      if (payload) {
        setIsExternalEditingInStore(true);
        setRequestIdInStore(payload.requestId);
      }
    } else {
      setTimeout(() => {
        setTabsAsEmptyInEditStore();
      }, 2000);
    }
    setIsSaveAndStay(false);
  };

  const handleUnassignAssets = async () => {
    if (tabName) {
      // Get checked assets from Edit Store
      let { checkedAssets: assetsToUnassign } = getCheckedAssetsAndTitlesByTabFromStore(tabName);

      setIsUnassigningAssets(true);

      assetsToUnassign = assetsToUnassign.map((asset) => {
        asset.title = {
          id: NIL_UUID,
          name: '',
        };
        asset.fields = {
          fileName: asset.fields.fileName,
          filePath: asset.fields.filePath,
          status: AssetStatus.Draft,
          originalFileName: asset.fields.originalFileName,
        };
        asset.assetGroup = {
          id: '',
          name: '',
        };
        return asset;
      });

      await putAssets({
        action: DraftAssetPatchAction.TitleUnassign,
        assets: assetsToUnassign,
      });

      // Remove Assets from Edit Store
      removeAssetsFromEditStore(assetsToUnassign);
      // Restore Assets in IngestionStore
      restoreAssetsToStore(assetsToUnassign);
      setIsUnassigningAssets(false);
      setUnassignedTab();
    }
  };

  const updateModifiedDate = (assets: Assets) => {
    const now = new Date();

    const newAssets = assets.map((asset: Asset) => {
      if (asset.modified) {
        const modifiedDate: UserState = {
          ...asset.modified,
          date: now,
        };
        return {
          ...asset,
          modified: modifiedDate,
        };
      } else return asset;
    });
    return newAssets;
  };

  const updateStatusToAsperaIngest = (assets: Assets) => {
    const newAssets = assets.map((asset: Asset) => {
      return {
        ...asset,
        asperaIngestStatus: AsperaIngestStatus.InProgress,
      };
    });
    return newAssets;
  };

  const updateAssetPreviews = (assets: Assets) => {
    const assetPreviewUpdates = getAssetPreviewUpdates;

    if (assetPreviewUpdates.length === 0) {
      return assets.map((asset: Asset) => {
        return { ...asset };
      });
    }

    const newAssets = assets.map((asset: Asset) => {
      const previewUpdates = assetPreviewUpdates.find((x) => x.assetId === asset.assetId);
      return previewUpdates
        ? {
            ...asset,
            previewId: previewUpdates.previewId ? previewUpdates.previewId : undefined,
            customPreviewId: previewUpdates.customPreviewId
              ? previewUpdates.customPreviewId
              : undefined,
          }
        : {
            ...asset,
          };
    });

    return newAssets;
  };

  useEffect(() => {
    const areRequired = areRequiredFieldsFilled(tabName);
    setIsSaveDisabled(!areRequired);
  });

  useEffect(() => {
    if (isWaitingForUploads && getIngestionAssetsInProgress === 0) {
      if (isSaveAndStay) {
        handleSaveAndStay();
      } else {
        handleSave();
      }
      setIsWaitingForUploads(false);
    }
  }, [getIngestionAssetsInProgress]);

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        key={vertical + horizontal}
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={handleCloseAlert} severity={alertMessage?.severity}>
          {alertMessage?.message}
        </Alert>
      </Snackbar>
      <div className={classes.container}>
        <Tooltip title={isSaveDisabled ? 'You need to fill the required fields' : ''}>
          <>
            <Button
              className={classes.saveButton}
              variant="contained"
              disabled={isSaveDisabled || getIsAnyUpdatePending || isCommitingToMAM}
              onMouseDown={(event) => {
                isDraft ? handleClickOnSave(false) : openMoreMenu(event);
              }}
              endIcon={!isDraft && <ExpandMore />}
            >
              {isCommitingToMAM ? <CircularProgress color="inherit" size={22} /> : <>Save</>}
            </Button>
            {!isDraft && (
              <Menu
                open={showMore}
                anchorEl={anchorEl}
                onClose={() => {
                  setShowMore(false);
                }}
              >
                <MenuItem onClick={() => handleClickOnSave(false)}>Save and Close...</MenuItem>
                <MenuItem onClick={() => handleClickOnSave(true)}>Save and Stay...</MenuItem>
              </Menu>
            )}
          </>
        </Tooltip>
        {!isDraft && (
          <Tooltip title={'Replace the Selected Asset(s)'}>
            <Button
              className={classes.actionButton}
              disabled={isReplaceDisabled}
              onClick={handleClickOpenReplacementModal}
            >
              Replace
            </Button>
          </Tooltip>
        )}
        {isDraft && tabName && (
          <Tooltip title="Unassign the Selected Asset(s)">
            <Button
              className={classes.saveButton}
              variant="contained"
              disabled={!isAnyTabInEditStore || numberOfCheckedAssets === 0 || isUnassigningAssets}
              onClick={handleUnassignAssets}
            >
              {isUnassigningAssets ? <CircularProgress color="inherit" size={15} /> : <>Unassign</>}
            </Button>
          </Tooltip>
        )}
        {getIsAnyUpdatePending && (
          <Button className={classes.progressButton}>
            <SyncIcon className={classes.spinningIcon} />
            Saving edit...
          </Button>
        )}
        {isDeleting && (
          <Button className={classes.progressButton}>
            <SyncIcon className={classes.spinningIcon} />
            Deleting assets...
          </Button>
        )}
        {!isDraft && (
          <Tooltip title={'Duplicate the Selected Asset(s) to ...'}>
            <Button
              className={classes.actionButton}
              disabled={!isAnyTabInEditStore || numberOfCheckedAssets === 0 || isUnassigningAssets}
              onClick={() => setOpenCopyDialog(true)}
            >
              Duplicate To
            </Button>
          </Tooltip>
        )}
        <ReplacementModal
          onCloseModal={handleClickCloseReplacementModal}
          isOpen={isReplacementModalOpen}
          asset={replacementAsset}
          tabName={tabName}
        />
        <Tooltip title={'Delete the Selected Asset(s)'}>
          <Button
            className={classes.actionButton}
            color="default"
            disabled={numberOfCheckedAssets === 0}
            onClick={() => setOpenDeleteModal(true)}
          >
            <SvgButton {...SvgButtonPresets.trash}></SvgButton>
            <span style={{ marginLeft: '.25rem' }}>Delete</span>
          </Button>
        </Tooltip>
        <AssetUploadProgress />
      </div>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onConfirm={handleDeleteAssets}
      />

      <AssetDuplicateDialog
        open={openCopyDialog}
        onClose={() => {
          setOpenCopyDialog(false);
        }}
        notify={(type, message) => {
          setOpen(true);
          setAlertMessage({
            severity: type === 'success' ? 'success' : 'error',
            message,
          });
        }}
        assets={checkedAssetsInTab}
        assetGroupName={tabName}
      ></AssetDuplicateDialog>
    </>
  );
};

export default observer(EditAssetButtons);
