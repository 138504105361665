import { AssetStatus } from 'base/enums/AssetStatus';
import { GroupType, UserDropdown } from 'base/models/DashboardProps';
import { RootStore } from 'base/stores/rootStore';
import { action, computed, makeObservable, observable } from 'mobx';

interface SnackbarSettings {
  isOpen?: boolean;
  message?: string;
  severity?: string;
}

export class DashboardStore {
  rootStore: RootStore;

  constructor(rootstore: RootStore) {
    makeObservable(this);
    this.rootStore = rootstore;
  }

  @observable userFilter: UserDropdown = { email: '', fullname: '' };
  @observable statusFilter = AssetStatus.Draft;
  @observable groupType = GroupType.TYPE_TITLE;
  @observable isBackdropOpen = false;
  @observable snackbarSettings: SnackbarSettings = {
    message: '',
    isOpen: false,
    severity: '',
  };

  @observable portalId = '00c1b88f-5cf3-471b-903e-ebc0efc67234';

  @action setIsBackdrop = (value: boolean) => {
    this.isBackdropOpen = value;
  };

  @action setUserFilterInStore = (userFilter: UserDropdown) => {
    this.userFilter = userFilter;
  };

  @action setStatusFilterInStore = (statusFilter: AssetStatus) => {
    this.statusFilter = statusFilter;
  };

  @action setGroupTypeInStore = (groupType: GroupType) => {
    this.groupType = groupType;
  };

  @action setSnackbarSettings = (isOpen?: boolean, message?: string, severity?: string) => {
    this.snackbarSettings = {
      message,
      isOpen,
      severity,
    };
  };

  @action setPortalId = (value: string) => {
    this.portalId = value;
  };

  @computed get getIsBackdropOpen() {
    return this.isBackdropOpen;
  }

  @computed get getSnackbarSettings() {
    return this.snackbarSettings;
  }

  @computed get getUserFilterInStore() {
    return this.userFilter;
  }

  @computed get getStatusFilterInStore() {
    return this.statusFilter;
  }

  @computed get getPortalId() {
    return this.portalId;
  }
}
