import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'end',
  },
  dashboardLabel: {
    color: '#575F72',
    fontSize: 24,
  },
  dashboardGrid: {
    marginTop: 12,
  },
}));

export const DivContainer = styled.div`
  overflow: hidden;
  width: 100%;
`;
