import React from 'react';

export const Buttons = {
  disband: {
    h: 16,
    w: 16,
    viewBox: '0 0 16 16',
    path: 'M6 7.25c0 .412-.338.75-.75.75h-1.5A.752.752 0 0 1 3 7.25v-1.5c0-.412.337-.75.75-.75h1.5c.412 0 .75.338.75.75v1.5zm5 0c0 .412-.338.75-.75.75h-1.5A.753.753 0 0 1 8 7.25v-1.5c0-.412.338-.75.75-.75h1.5c.412 0 .75.338.75.75v1.5zm-5 5c0 .412-.338.75-.75.75h-1.5a.752.752 0 0 1-.75-.75v-1.5c0-.412.337-.75.75-.75h1.5c.412 0 .75.338.75.75v1.5zm5 0c0 .412-.338.75-.75.75h-1.5a.753.753 0 0 1-.75-.75v-1.5c0-.412.338-.75.75-.75h1.5c.412 0 .75.338.75.75v1.5zm3.251-9.75L16 .751V0h-.751L13.5 1.749L11.751 0H11v.751L12.749 2.5L11 4.249V5h.751L13.5 3.251L15.249 5H16v-.751zM0 12h1v2H0v-2zm0-3h1v2H0V9zm13-2h1v2h-1V7zm0 6h1v2h-1v-2zm0-3h1v2h-1v-2zM0 6h1v2H0V6zm0-3h1v2H0V3zm8-1h2v1H8V2zM5 2h2v1H5V2zM2 2h2v1H2V2zm5 13h2v1H7v-1zm3 0h2v1h-2v-1zm-6 0h2v1H4v-1zm-3 0h2v1H1v-1z',
  },
};

const SvgButton: React.FC<{
  path: string;
  h: number;
  w: number;
  viewBox?: string;
  fill?: string;
  preserveAspectRatio?: string;
}> = ({
  path = '',
  h = 18,
  w = 18,
  viewBox = '0 0 18 18',
  fill = 'currentColor',
  preserveAspectRatio = 'xMidYMid meet',
}) => {
  return (
    <>
      <svg
        width={w}
        height={h}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio={preserveAspectRatio}
      >
        <path fillRule="evenodd" clipRule="evenodd" d={path} fill={fill} />
      </svg>
    </>
  );
};

export default SvgButton;
