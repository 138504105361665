import React, { useEffect } from 'react';

import { ROUTES } from 'base/constants/route';
import { store } from 'base/stores/rootStore';
//import { getUserInfo } from 'base/api/auth';
import localStorageAccess, { LocalStorageKeys } from 'base/utils/localStorageAccess';

const Authenticate: React.FC = () => {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || store.settingsStore.keys?.CLIENT_ID;
  let redirectUrl = `${window.location.protocol}//${window.location.host}/${ROUTES.LOGIN_CALLBACK_DMDC}`;
  let authenticationUrl = process.env.REACT_APP_MYID_URL || store.settingsStore.keys?.MYID_URL;

  const lowerHostname = window.location.hostname.toLowerCase();

  if (lowerHostname.includes('disneyscreeners')) {
    authenticationUrl = store.settingsStore.keys?.DISNEYSCREENERS_LOGIN_URL;
    redirectUrl = store.settingsStore.keys?.DISNEYSCREENERS_LOGIN_RETURN_URL || redirectUrl;
  } else if (lowerHostname.includes('disneyinflight')) {
    authenticationUrl =
      process.env.REACT_APP_DIF_MYID_URL || store.settingsStore.keys?.DIF_MYID_URL;
  }

  const url = new URL(authenticationUrl ?? '');

  url.searchParams.append('client_id', CLIENT_ID ?? '');
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('scope', 'openid');
  url.searchParams.append('redirect_uri', redirectUrl ?? '');

  useEffect(() => {
    async function auth() {
      // the intention here is to make a call to getUserInfo which will leverage dmdstats cookie and will create local storage userinfo object based on this cookie
      // right now this API call does not send credentials/cookies with it so it does not work as expected
      // will comment this out for now not to show a failing api call

      //const response = await getUserInfo('');
      const response: any = {};

      if (response.response && response.response.data) {
        localStorageAccess.setToLocalStorage(LocalStorageKeys.userInfo, response.response.data);
      } else {
        window.location.href = url.toString();
      }
    }

    auth();
  }, []);

  return null;
};
export default Authenticate;
