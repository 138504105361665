import { SortGroup } from 'base/models/Asset';
import React, { useState } from 'react';

import { ProductCollection } from '../components/ProductCollection';
import { v4 as uuidv4 } from 'uuid';

import { useRootStoreContext } from 'base/stores/rootStore';
import { observer } from 'mobx-react-lite';

import '../styles/flex.css';
import '../styles/sort-and-group.css';
import { LayoutSize } from '../models/SortAndGroup.types';
import { EditGroup } from '../components/EditGroup';

interface SortAndGroupProps {
  size: LayoutSize;
}

const SortAndGroup: React.FC<SortAndGroupProps> = ({ size }) => {
  const [toggleView, setToggleView] = useState<'show-all' | 'edit-group-view'>('show-all');
  const [editGroupInfo, setEditGroupInfo] = useState({
    idx: null as unknown,
    group: {} as SortGroup,
  });

  const { filteredSortGroups, setSortGroups, editSortGroup } = useRootStoreContext().groupingStore;

  const openGroupView = (idx: number, group: SortGroup) => {
    setToggleView('edit-group-view');
    setEditGroupInfo({ idx, group });
  };
  return (
    <div className={`dmdc-sort-root  dmdc-sort-${size.charAt(0)}`}>
      {toggleView === 'show-all' ? (
        <ProductCollection openGroupView={openGroupView} />
      ) : (
        editGroupInfo.group && (
          <EditGroup
            idx={editGroupInfo.idx as number}
            group={editGroupInfo.group}
            close={() => {
              setToggleView('show-all');
            }}
            update={(group) => {
              editSortGroup(group);
            }}
            disband={(assets) => {
              const copyItems = filteredSortGroups.slice(0);
              copyItems.splice(
                editGroupInfo.idx as number,
                1,
                ...assets.map(
                  (asset) =>
                    ({
                      id: `G-${asset.id}-${uuidv4()}`,
                      name: null,
                      assets: [asset],
                      type: 'group',
                    } as unknown as SortGroup)
                )
              );
              setSortGroups(copyItems);
              setToggleView('show-all');
            }}
            removeOne={(newGroup, removedAsset) => {
              const copyItems = filteredSortGroups.slice(0);
              copyItems.splice(editGroupInfo.idx as number, 1, newGroup);
              copyItems.splice((editGroupInfo.idx as number) + 1, 0, {
                id: `G-${removedAsset.id}-${uuidv4()}`,
                name: null,
                assets: [removedAsset],
                type: 'group',
              } as unknown as SortGroup);
              setSortGroups(copyItems);
            }}
          />
        )
      )}
    </div>
  );
};
export default observer(SortAndGroup);
