import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

interface SortableCellProps {
  value: JSX.Element;
}

const SortableCell = SortableElement(({ value }: SortableCellProps) => {
  return <>{value}</>;
});

export default SortableCell;
