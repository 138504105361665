import React, { Dispatch, SetStateAction } from 'react';
import { AppBar, Toolbar, Grid } from '@material-ui/core';

import { useLocation } from 'react-router-dom';
import { useStyles } from './Navbar.styles';

import { appKey, description, marketingSubdomain } from 'components/App/App.component';

import { ContextSwitcher } from 'components/shared/ContextSwitcher';
import { ScreenersWidgetProps } from 'base/enums/ActionCodes';

interface NavbarProps {
  children: React.ReactNode;
  setIsOpenMenu: Dispatch<SetStateAction<boolean>>;
}

const Navbar: React.FC<NavbarProps> = ({ setIsOpenMenu, children }: NavbarProps) => {
  const classes = useStyles();
  const [, location] = useLocation().pathname.split('/');

  const getMarketingUrl = () => {
    const urlSegments = window.location.hostname.split('.');
    urlSegments.shift();

    const marketingUrl =
      marketingSubdomain && urlSegments.length > 0
        ? `${window.location.protocol}//${marketingSubdomain}.${urlSegments.join('.')}`
        : '/';

    return marketingUrl;
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={
          !location || location === ScreenersWidgetProps.UrlName ? classes.dashboardAppBar : ''
        }
      >
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Grid container justify="flex-start" alignItems="flex-end">
                <Grid item>
                  <a href={getMarketingUrl()}>
                    <img
                      src={`/assets/images/logo_${appKey}.svg`}
                      className={classes.logo}
                      alt={description}
                    />
                  </a>
                </Grid>
                {window.location.href.toLowerCase().includes('uploadscreeners') ? (
                  <ContextSwitcher></ContextSwitcher>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {/* {children} DISABLE the menu. New Component should be written with new nav service. Which is outside of scope of MAM */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
