import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { DivContainer } from './Loading.styles';

const Loading = () => {
  return (
    <DivContainer>
      <CircularProgress size={40} thickness={4} />
    </DivContainer>
  );
};

export default Loading;
