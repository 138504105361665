import React, { Fragment, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import BaseFieldProps from 'base/models/BaseFieldProps';
import { TemplateFieldOption } from 'base/models/Template';

import { useStyles, propsMenuItemsBellowControl } from './InputSelect.styles';
import { useRootStoreContext } from 'base/stores/rootStore';
import { IdValueField, InputSelectRule } from 'base/models/Asset';

export interface InputSelectProps extends BaseFieldProps {
  value?: string;
  errorMessage?: string;
  childFieldName?: string;
  options: TemplateFieldOption[];

  assetId: string;
  tabName: string;
  titleName: string;
  isBulkEdit?: boolean;
}
const InputSelect: React.FC<InputSelectProps> = ({
  value,
  label,
  errorMessage,
  isRequired,
  isDisabled,
  isVisible,
  isIdValue,
  fieldName,
  dmdcTargetField,
  childFieldName,
  options,
  onChange,
  onBlur,

  assetId,
  tabName,
  titleName,
  isBulkEdit,
}: InputSelectProps) => {
  const classes = useStyles();
  const {
    updateInputSelectRule,
    setNewInputSelectRule,
    setNewBulkInputSelectRule,
    updateBulkInputSelectRule,
    setAssetFieldFromTabInStore,
  } = useRootStoreContext().editStore;
  const [selectedOption, setSelectedOption] = React.useState('');
  const [displaySelectedOption, setDisplaySelectedOption] = React.useState('');

  useEffect(() => {
    if (isIdValue) {
      const idValueObject = value as unknown as IdValueField;
      value = `${idValueObject.id}|${idValueObject.value}`;
    }
    if (value && !isBulkEdit) setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    if (isIdValue && typeof value !== 'string') {
      const idValueObject = value as unknown as IdValueField;
      value = `${idValueObject.id}|${idValueObject.value}`;
    }

    const newInputSelectRule: InputSelectRule = {
      fieldName,
      parentFieldName: fieldName,
      parentFieldValue: (value as string) || '',
      childFieldName: childFieldName || '',
    };
    if (isBulkEdit) setNewBulkInputSelectRule(newInputSelectRule);
    else setNewInputSelectRule(newInputSelectRule, tabName, titleName, assetId);
  }, []);

  const handleChange = (targetValue: string) => {
    if (isIdValue && targetValue) {
      const splitedTargetValue = targetValue.split('|');
      const [, value] = splitedTargetValue;
      setDisplaySelectedOption(value);
    }

    setSelectedOption(targetValue);

    if (onChange) {
      onChange(fieldName, targetValue);
      if (childFieldName) {
        setAssetFieldFromTabInStore(childFieldName, '', tabName, titleName, assetId);
      }
      const newInputSelectRule: InputSelectRule = {
        fieldName,
        parentFieldName: fieldName,
        parentFieldValue: targetValue as string,
        childFieldName: childFieldName || '',
      };
      if (isBulkEdit) updateBulkInputSelectRule(newInputSelectRule);

      updateInputSelectRule(newInputSelectRule, tabName, titleName, assetId);
    }
  };

  const handleValidateField = () => {
    if (onBlur) {
      if (selectedOption !== 'undefined|undefined') {
        if (isIdValue) {
          const splitedSelectedOption = selectedOption.split('|');
          const [, displayValue] = splitedSelectedOption;

          const idValueField: IdValueField = {
            id: parseInt(selectedOption),
            value: displaySelectedOption ? displaySelectedOption : displayValue,
          };

          onBlur(fieldName, idValueField);
        } else {
          onBlur(fieldName, selectedOption);
        }
      }
    }
  };

  return (
    <Fragment>
      {isVisible && (
        <FormControl
          className={classes.formControl}
          disabled={isDisabled}
          required={isRequired}
          hidden={isVisible ? false : true}
        >
          <InputLabel htmlFor={`select-${dmdcTargetField}`} id={`label-${fieldName}`}>
            {label}
          </InputLabel>
          <Select
            defaultValue={value}
            labelId={`label-${fieldName}`}
            id={`select-${dmdcTargetField}`}
            value={selectedOption}
            name={`select-${fieldName}`}
            onChange={(event) => handleChange(event.target.value as string)}
            onBlur={handleValidateField}
            data-testid={`select-${dmdcTargetField}`}
            MenuProps={{ ...propsMenuItemsBellowControl }}
          >
            {options?.map((option: TemplateFieldOption) => (
              <MenuItem
                key={option.value}
                value={isIdValue ? `${option.value}|${option.display}` : option.value}
              >
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Fragment>
  );
};

export default InputSelect;
