import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dmdcDefaultImage: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
    dmdcPreviewMagnifier: {
      height: '100%',
      '& >div': {
        height: '100%',
      },
      '& >div>img[alt="Preview"]': {
        height: '100%',
        'object-fit': 'contain',
      },
    },
    dmdcZoomMagnifierContainer: {
      position: 'absolute',
      top: '30%',
      right: '30px',
      width: '75%',
      zIndex: 9999,
    },
    loupeContainer: {
      height: 72,
      width: 72,
    },
    badge: {
      '& .MuiIconButton-root:hover': {
        background: 'transparent',
      },
      '& .MuiSvgIcon-root': {
        fill: '#fff',
        background: '#3DA8DF',
      },
    },
  })
);
