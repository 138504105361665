import React from 'react';
import { Checkbox, TableHead, TableRow, Grid, TableSortLabel } from '@material-ui/core';
import { SortableContainer } from 'react-sortable-hoc';

import { CellHeaderSticky, CheckboxHeaderContainer } from '../EditGrid.styles';

interface SortableContainerProps {
  children: React.ReactNode;
  handleCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  areAssetsChecked: (tabName: string, titleName: string) => boolean | undefined;
  tabName: string;
  titleName: string;
  orderByFileName: boolean;
  sortOrder: 'asc' | 'desc';
  handleSort: (fieldName: string) => void;
}

const SortableHead = SortableContainer(
  ({
    children,
    handleCheckbox,
    areAssetsChecked,
    tabName,
    titleName,
    orderByFileName,
    sortOrder,
    handleSort,
  }: SortableContainerProps) => {
    return (
      <TableHead>
        <TableRow>
          <CellHeaderSticky align="left">
            <Grid container wrap="nowrap" alignItems="center">
              <CheckboxHeaderContainer>
                <div>
                  <Checkbox
                    color="primary"
                    onChange={handleCheckbox}
                    checked={areAssetsChecked(tabName, titleName)}
                  />
                </div>
              </CheckboxHeaderContainer>
              <TableSortLabel
                active={orderByFileName}
                direction={sortOrder}
                onClick={() => handleSort('fileName')}
              >
                File Name
              </TableSortLabel>
            </Grid>
          </CellHeaderSticky>
          {/** children are all sortable cells! */}
          {children}
        </TableRow>
      </TableHead>
    );
  }
);

export default SortableHead;
