import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { assetStatusColorMap } from 'base/models/StatusColorMap';
import { AssetStatus } from 'base/enums/AssetStatus';

export const StyledToggleButton = withStyles(() => ({
  root: {
    height: 76,
    width: 112,
    padding: 6,
    '& ,&:not(:last-child), &:not(:first-child)': {
      borderTopRightRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',
    },
    [`&[value='${AssetStatus.Draft}']`]: {
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: `${assetStatusColorMap[AssetStatus.Draft].primary}`,
      },
    },
    [`&[value='${AssetStatus.InProgress}']`]: {
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: `${assetStatusColorMap[AssetStatus.InProgress].primary}`,
      },
    },
    [`&[value='${AssetStatus.Published}']`]: {
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: `${assetStatusColorMap[AssetStatus.Published].primary}`,
      },
    },
    [`&[value='${AssetStatus.NotPublished}']`]: {
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: `${assetStatusColorMap[AssetStatus.NotPublished].primary}`,
      },
    },
    [`&[value='${AssetStatus.Holdback}']`]: {
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: `${assetStatusColorMap[AssetStatus.Holdback].primary}`,
      },
    },
    [`&[value='${AssetStatus.Failed}']`]: {
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: `${assetStatusColorMap[AssetStatus.Failed].primary}`,
      },
    },
    [`&.Mui-selected[value='${AssetStatus.Draft}']`]: {
      backgroundColor: assetStatusColorMap[AssetStatus.Draft].primary,
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: assetStatusColorMap[AssetStatus.Draft].contrast,
      },
    },
    [`&.Mui-selected[value='${AssetStatus.InProgress}']`]: {
      backgroundColor: assetStatusColorMap[AssetStatus.InProgress].primary,
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: assetStatusColorMap[AssetStatus.InProgress].contrast,
      },
    },
    [`&.Mui-selected[value='${AssetStatus.Published}']`]: {
      backgroundColor: assetStatusColorMap[AssetStatus.Published].primary,
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: assetStatusColorMap[AssetStatus.Published].contrast,
      },
    },
    [`&.Mui-selected[value='${AssetStatus.NotPublished}']`]: {
      backgroundColor: assetStatusColorMap[AssetStatus.NotPublished].primary,
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: assetStatusColorMap[AssetStatus.NotPublished].contrast,
      },
    },
    [`&.Mui-selected[value='${AssetStatus.Holdback}']`]: {
      backgroundColor: assetStatusColorMap[AssetStatus.Holdback].primary,
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: assetStatusColorMap[AssetStatus.Holdback].contrast,
      },
    },
    [`&.Mui-selected[value='${AssetStatus.Failed}']`]: {
      backgroundColor: assetStatusColorMap[AssetStatus.Failed].primary,
      '& .dmdc-ai-status-btn-card-content': {
        borderLeftColor: assetStatusColorMap[AssetStatus.Failed].contrast,
      },
    },
    '& .dmdc-ai-status-btn-card-content, & .MuiCardContent-root.dmdc-ai-status-btn-card-content': {
      padding: '0 0 0 15px',
      borderLeft: `5px solid transparent`,
    },
  },
}))(ToggleButton);

export const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },

  grouped: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },

    border: 'none',
  },
}))(ToggleButtonGroup);

export const GridContainer = withStyles(() => ({
  root: {
    container: 'true',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}))(Grid);

export const GridItem = withStyles(() => ({
  root: {
    alignSelf: 'center',
    border: 'none',
    boxShadow: 'none',
  },
}))(Grid);

export const DivContainerLoading = styled.div`
  padding-left: 200px;
`;
