import React, { useEffect, useState } from 'react';
import { postSessionKey } from 'base/api/auth';
import localStorageAccess, { LocalStorageKeys } from 'base/utils/localStorageAccess';
import { Redirect, Route, useHistory, useLocation } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { appStateHelper } from 'base/utils/appStateHelper';

const LoginCallback: React.FC = () => {
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code') ?? '';

  useEffect(() => {
    async function auth() {
      queryParams.delete('code');
      history.replace({
        search: queryParams.toString(),
      });
      const response = await postSessionKey(code);
      if (response.response && response.response.data) {
        localStorageAccess.setToLocalStorage(LocalStorageKeys.userInfo, response.response.data);
        setIsLogged(true);
      }
    }
    auth();
  }, []);

  const renderRoute = () => {
    const redirectTo = appStateHelper.requestToken.getRequestTokenState();

    return <Route render={() => <Redirect to={redirectTo} />} />;
  };

  return isLogged ? (
    renderRoute()
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <CircularProgress />
    </div>
  );
};
export default LoginCallback;
