import React from 'react';
import { useStyles } from './StatusButtonFilter.styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { assetStatusText } from 'base/models/DashboardProps';
import { observer } from 'mobx-react-lite';

interface StatusButtonFilterProps {
  totalAssets: number;
  status: assetStatusText;
}

const StatusButtonFilter: React.FC<StatusButtonFilterProps> = ({ totalAssets, status }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={'dmdc-ai-status-btn-card-content'}>
        <Typography className={classes.title}>{totalAssets}</Typography>
        <Typography className={classes.text}>{status}</Typography>
      </CardContent>
    </Card>
  );
};

export default observer(StatusButtonFilter);
