import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import styled from 'styled-components';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
      fontSize: '16px',
      '&:not(.marked-title) .edit-all-selected': {
        display: 'none',
        overflow: 'hidden',
        height: 0,
        width: 0,
      },
      '& .MuiDataGrid-main': {
        paddingBottom: '15px',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-cell': {
        color: '#7E899E',
        border: 'none',
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: 'rgb(225,225,225)',
      },
      '& .MuiDataGrid-columnHeader': {
        color: '#575F72',
        fontSize: '16px',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: '0',
      },
      '& .MuiDataGrid-footer': {
        borderTop: '12px solid #e7e5e9',
        borderRadius: '4px',
      },
      '& .TotalAsset-cell': {
        color: '#333A46',
      },
      '& .MuiPaginationItem-icon': {
        color: '#216085',
      },
      '& .Mui-odd.MuiDataGrid-row:not(.Mui-selected)': {
        backgroundColor: 'rgba(0,0,0,.04)',
        '& .asset-list-cta button': {
          backgroundColor: '#fff',
        },
      },
      '& .edit-all-selected': {
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: '16px',
      },
      '& .edit-asset-button': {
        padding: 0,
        minWidth: 'auto',
      },
      '& .MuiTypography-root.MuiTablePagination-caption': {
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#575F72',
      },
    },
    button: {
      textTransform: 'none',
      border: '1px solid #575F72',
      color: '#575F72',
      borderRadius: '4px',
      textAlign: 'center',
      fontSize: 16,
      padding: '6px 18px',
      '&:hover': {
        background: '#575F72',
        color: '#fff',
      },
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '9px',
    },
    snackbar: {
      backgroundColor: green[600],
    },
  })
);

export const DivError = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
`;

export const DivButtonEdit = styled.div`
  text-align: right;
`;

export const DivButtonPublish = styled.div`
  margin-right: 24px;
`;

export const DivButtonDelete = styled.div`
  margin-right: 24px;
`;

export const DivButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 16px;
`;
