import React, { Ref, useImperativeHandle, useRef } from 'react';
import { SortGroup, SortGroupAsset } from 'base/models/Asset';
import { ReactSortable } from 'react-sortablejs';

import { useRootStoreContext } from 'base/stores/rootStore';

import { useStyles } from './ChildrenContainer.styles';

import { SortableOptions } from '../SortableOptions.variables';
import { ImageWithDefault } from '../ImageWithDefault';

interface SortableChildAssets {
  group: SortGroup;
  ref: Ref<any>;
  mainAsset: SortGroupAsset;
}

const ChildrenContainer: React.FC<SortableChildAssets> = React.forwardRef(
  (props, ref: Ref<any>) => {
    const { updateGroupAssets, setIsDragging } = useRootStoreContext().groupingStore;

    const classes = useStyles({});
    // const assets = props.group.assets.filter((asset) => asset.id !== props.mainAsset.id);
    const [, ...assets] = props.group.assets;

    const childAssetsRef = useRef<any | null>(null);

    const scrollOptions = {
      top: 0,
      left: 0,
      behavior: 'smooth',
    };

    useImperativeHandle(ref, () => ({
      scrollToLeft() {
        if (childAssetsRef.current) {
          scrollOptions.left = -85;
          childAssetsRef.current.ref.current.scrollBy(scrollOptions);
        }
      },

      scrollToRight() {
        if (childAssetsRef.current) {
          scrollOptions.left = 85;
          childAssetsRef.current.ref.current.scrollBy(scrollOptions);
        }
      },
    }));
    return (
      <div
        className={`dmdc-sort-group--children-container-wrapper ${
          assets.length ? 'dmdc-sort-group--children-container-not-empty' : null
        }  `}
      >
        <ReactSortable
          list={assets}
          onChoose={() => setIsDragging(true)}
          onUnchoose={() => setIsDragging(false)}
          setList={(currentList) => {
            updateGroupAssets(props.group.id, [props.mainAsset, ...currentList]);
          }}
          {...SortableOptions}
          className={`dmdc-sort-group--children-container`}
          ref={childAssetsRef}
        >
          {assets.map((asset) => (
            <div
              title={asset.name}
              key={`${props.group.id}-${asset.id}`}
              className={`dmdc-sort-group--child ${classes.cardMediaCentered}`}
            >
              <ImageWithDefault src={asset?.imageUrl} alt={asset?.name}></ImageWithDefault>
            </div>
          ))}
        </ReactSortable>
      </div>
    );
  }
);

export default ChildrenContainer;
