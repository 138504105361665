import React, { useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { BootstrapDialog } from './ReplacementModal.styles';
import { ReplacementUpload } from 'components/EditAsset/EditAssetButtons/ReplacementModal/ReplacementUpload';
import { FileInfo } from './FileInfo';
import { Asset } from 'base/models/Asset';
import dayjs from 'dayjs';
import { DraftReplacement, ReplacementStatus } from 'base/models/Replacement';
import { startReplacement } from 'base/api/replacement';
import { useRootStoreContext } from 'base/stores/rootStore';
import { ReplacementStatusEnum } from 'base/enums/AssetStatus';
import { HTTP_OK_STATUS } from 'base/constants/upload';

interface BootstrapDialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
  id: string;
}

const BootstrapDialogTitle = ({ children, onClose, id }: BootstrapDialogTitleProps) => {
  return (
    <DialogTitle id={id}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface ReplacementProps {
  onCloseModal: () => void;
  isOpen: boolean;
  asset: Asset | null;
  tabName: string;
}

const ReplacementModal = ({ onCloseModal, isOpen, asset, tabName }: ReplacementProps) => {
  const { setReplacementStatusInAsset } = useRootStoreContext().editStore;
  const [draftReplacement, setDraftReplacement] = useState<DraftReplacement | null>(null);
  const [assetReplacement, setAssetReplacement] = useState<Asset | null>(null);
  const [newAssetUrl, setNewAssetUrl] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setDraftReplacement(null);
      setAssetReplacement(null);
      setNewAssetUrl('');
    }
  }, [isOpen]);

  const handleClick = async () => {
    if (draftReplacement) {
      const { response } = await startReplacement(draftReplacement);
      const status = response?.status;
      if (status && status === HTTP_OK_STATUS) {
        const status: ReplacementStatus = {
          id: ReplacementStatusEnum.InProgress,
          name: ReplacementStatusEnum[ReplacementStatusEnum.InProgress],
        };
        setReplacementStatusInAsset(draftReplacement.assetId, tabName, status);
      } else {
        const status: ReplacementStatus = {
          id: ReplacementStatusEnum.Failure,
          name: ReplacementStatusEnum[ReplacementStatusEnum.Failure],
        };
        setReplacementStatusInAsset(draftReplacement.assetId, tabName, status);
      }
      onCloseModal();
      setDraftReplacement(null);
    }
  };

  const formatSize = (size: string) => {
    if (size) {
      let sizeAsString = '';

      const sizeAsNumber = Math.floor(parseInt(size) / 1024 / 1024);
      if (sizeAsNumber < 1) {
        sizeAsString = 'Less than 1 MB';
      } else {
        sizeAsString = `${sizeAsNumber} MB`;
      }
      return sizeAsString;
    } else return '';
  };

  const dateFormat = 'MM/DD/YYYY';
  const lastModified =
    asset?.modified?.date ?? asset?.created?.date ?? asset?.updatedAt ?? Date.now();

  return (
    <div>
      <BootstrapDialog
        onClose={onCloseModal}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth
        maxWidth="md"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onCloseModal}>
          Replace Assets
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FileInfo
            title="Current File:"
            fileInfo={{
              fileName: asset?.fields.fileName ?? '',
              shortId: asset?.fields['mediaID'] ?? '',
              size: formatSize(asset?.fields['fileSize']),
              lastModified: dayjs(lastModified).format(dateFormat),
            }}
            asset={asset}
          />
          {draftReplacement && asset && (
            <FileInfo
              title="New File:"
              fileInfo={{
                fileName: draftReplacement.fileName,
                shortId: draftReplacement.shortId,
                size: formatSize(draftReplacement.size.toString()),
                lastModified: dayjs(draftReplacement.lastModified ?? '').format(dateFormat),
              }}
              asset={assetReplacement}
              newPreviewURL={newAssetUrl}
            />
          )}
          <ReplacementUpload
            textContent="Drag and drop your file to replace"
            textButton="Add More"
            setDraftReplacement={setDraftReplacement}
            setAssetReplacement={setAssetReplacement}
            setNewAssetUrl={setNewAssetUrl}
            currentAsset={asset}
            onCloseModal={onCloseModal}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onCloseModal}>
            Cancel
          </Button>
          <Button autoFocus onClick={handleClick} disabled={!assetReplacement && !draftReplacement}>
            Replace
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ReplacementModal;
