import api from './config';
import { resolvePromise } from '../utils/resolveAPICall';
import { RequestTokenResponse } from 'base/models/RequestToken';

export const requestToken = async (token: string) => {
  const response = await resolvePromise(
    api.post<RequestTokenResponse>(`requests/validate`, { token })
  );

  return response;
};
