import React from 'react';

import { Button } from '@material-ui/core';
import { GridRowData } from '@material-ui/data-grid';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { ROUTES } from 'base/constants/route';
import { useRootStoreContext } from 'base/stores/rootStore';
import { useStyles } from 'components/shared/Button/ColorButton';
import { useHistory } from 'react-router-dom';

import { getDraftsFromAssets } from 'base/api/draft';
import { AssetStatus } from 'base/enums/AssetStatus';
import { Asset } from 'base/models/Asset';
import { getAssetsWithUploadProperties, getTemplateFromCached } from '../Dashboard.functions';

import QUERY_IDENTIFIERS from 'base/constants/reactQueryIdentifiers';
import { hasTitleOrAssetGroup } from 'base/utils/editFunctions';
import { useQueryClient } from 'react-query';

interface Props {
  data: GridRowData;
}

const EditAssetButton: React.FC<Props> = (props) => {
  const { data } = props;
  const history = useHistory();
  const classes = useStyles();
  const { setAssetsInStore } = useRootStoreContext().ingestionStore;
  const { startTabsInEditStore, setIsExternalEditingInStore, setRequestIdInStore } =
    useRootStoreContext().editStore;
  const {
    assetsStore: { setIsLoadingGrid: setIsLoading },
  } = useRootStoreContext();
  const templateQueryClient = useQueryClient();

  const fetchTemplate = async (templateId: string) => {
    const queryObject: any = templateQueryClient.getQueryData([QUERY_IDENTIFIERS.ASSET_TEMPLATES]);

    if (queryObject && queryObject.data) {
      const template = getTemplateFromCached(templateId, queryObject.data);
      if (template) return template;
    }
  };

  const handleClick = async () => {
    setIsLoading(true);
    const [firstAsset] = data.assets;
    if (firstAsset.status.id === AssetStatus.Draft || firstAsset.status.id === AssetStatus.Failed) {
      const assetsWithUploadProperties = getAssetsWithUploadProperties(data.assets);
      if (!hasTitleOrAssetGroup(firstAsset)) setAssetsInStore(assetsWithUploadProperties); // for the unassigned assets

      const assetToRedirect: Asset | undefined = data.assets.find(
        (asset: Asset) => asset.assetGroup && asset.assetGroup.name
      );

      let template: any;
      if (firstAsset && firstAsset.templateId) {
        template = await fetchTemplate(firstAsset.templateId);
        startTabsInEditStore(assetsWithUploadProperties, template);
      }
      if (assetToRedirect)
        history.push({
          pathname: `/${ROUTES.EDIT}`,
          state: { assetGroupName: assetToRedirect.assetGroup.name },
        });
      else history.push(`/${ROUTES.EDIT}`);
    } else {
      const assetIds = data.assets.map((asset: Asset) => asset.assetId);

      const { response } = await getDraftsFromAssets(assetIds);
      const payload = response?.data;
      if (payload) {
        const assetsWithUploadProperties = getAssetsWithUploadProperties(payload.assets);
        const assets = assetsWithUploadProperties.map((asset: Asset) => {
          return {
            ...asset,
            status: payload.assets[0].status,
          };
        });
        if (!hasTitleOrAssetGroup(firstAsset)) setAssetsInStore(assets);

        const assetToRedirect: Asset | undefined = payload.assets.find(
          (asset: Asset) => asset.assetGroup && asset.assetGroup.name
        );

        setIsExternalEditingInStore(true);
        setRequestIdInStore(payload.requestId);
        let template: any;
        if (payload.assets[0] && payload.assets[0].templateId) {
          template = await fetchTemplate(payload.assets[0].templateId);
          startTabsInEditStore(assets, template);
        }
        if (assetToRedirect)
          history.push({
            pathname: `/${ROUTES.EDIT}`,
            state: { assetGroupName: assetToRedirect.assetGroup.name },
          });
        else history.push(`/${ROUTES.EDIT}`);
      }
    }
  };

  return (
    <Button className="edit-asset-button" onClick={handleClick}>
      <CreateOutlinedIcon className={classes.iconButton}></CreateOutlinedIcon>
    </Button>
  );
};

export default EditAssetButton;
