import { DataGrid } from '@material-ui/data-grid';
import { ColumnsTitle } from './Columns/Columns';
import React, { useEffect, useState } from 'react';
import { GetAssetsByPackageId } from 'base/api/asset';
import { DigitalScreenerComponentList } from 'base/models/Asset';
import { Loading } from 'components/Dashboard/Loading';

interface DigitalScreenerListProps {
  packageId: string;
}

const DigitalScreenerList: React.FC<DigitalScreenerListProps> = ({ packageId }) => {
  const [componentList, setcomponentList] = useState<DigitalScreenerComponentList>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getComponentsByPackageID = async () => {
    if (packageId) {
      const assetComponentList = await GetAssetsByPackageId(packageId);
      if (assetComponentList) {
        if (assetComponentList.response) {
          setcomponentList(assetComponentList.response.data);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getComponentsByPackageID();
  }, []);

  if (isLoading) return <Loading />;
  return (
    <DataGrid
      rows={componentList}
      columns={ColumnsTitle}
      pageSize={15}
      autoHeight={true}
      sortingOrder={['desc', 'asc']}
      rowsPerPageOptions={[10]}
      getRowId={(row: any) => row.assetId}
      rowHeight={25}
    />
  );
};
export default DigitalScreenerList;
