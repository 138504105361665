import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { requestToken } from 'base/api/request';
import { RequestTokenResponse, RequestType } from 'base/models/RequestToken';
import { ROUTES } from 'base/constants/route';
import { useRootStoreContext } from 'base/stores/rootStore';

export const RequestToken: React.FC = () => {
  const { setIsExternalEditingInStore, setIngestErrorInStore, setIsExternalAddingInStore } =
    useRootStoreContext().editStore;
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token') as string;
  const history = useHistory();

  const setError = (errorMessage: string) => {
    setIngestErrorInStore(true, errorMessage);
    setTimeout(() => {
      history.push(`/`);
    }, 3000);
  };

  useEffect(() => {
    const validateRequestToken = async () => {
      const { response, error } = await requestToken(token);

      if (!error && response && response.data && response.status === 200) {
        const requestTokenResponse: RequestTokenResponse = response.data;
        switch (requestTokenResponse.requestType) {
          case RequestType.Edit:
            setIsExternalEditingInStore(true); // Flag to know we're editing from DMDC

            history.push({
              pathname: `/${ROUTES.EDIT_FROM_APPLICATION}`,
              search: `?requestId=${requestTokenResponse.correlationId}`,
            });
            break;
          case RequestType.Add:
            setIsExternalAddingInStore(true); // Flag to know we're adding from DMDC

            history.push({
              pathname: `/${ROUTES.ADD_FROM_APPLICATION}`,
              search: `?requestId=${requestTokenResponse.correlationId}`,
            });
            break;
        }
      } else {
        if (response?.status === 400) setError('Bad request.');
        else if (response?.status === 401) setError('Your token expired, please try again.');
      }
    };

    if (token) validateRequestToken();
    else setError('Invalid token, please try again.');
  }, []);

  return <div>Loading...</div>;
};
export default RequestToken;
