import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { GridRowData } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { extraStyleProps, useStyles } from './AssetList.styles';

import { CancelOutlined, EditOutlined, MoreHoriz } from '@material-ui/icons';
import LaunchIcon from '@material-ui/icons/Launch';
import { Asset, Assets } from 'base/models/Asset';
import { AssetStatusFormat } from '../AssetStatusFormat';

import { buildPreviewCdnUrl } from 'base/api/preview';
import { ROUTES } from 'base/constants/route';
import { AssetStatus } from 'base/enums/AssetStatus';
import { useRootStoreContext } from 'base/stores/rootStore';
import { useHistory } from 'react-router-dom';
import { CustomPreviewMenu } from '../CustomPreview';
import { getAssetsWithUploadProperties, getTemplateFromCached } from '../Dashboard.functions';

import { postAssetDeleteRequest } from 'base/api/asset';
import { deleteDraft, getDraftsFromAssets } from 'base/api/draft';

import { PREVIEW_DEFAULT_IMAGE, SIZES } from 'base/constants/previewFiles';
import { hasTitleOrAssetGroup } from 'base/utils/editFunctions';

import QUERY_IDENTIFIERS from 'base/constants/reactQueryIdentifiers';
import { previewToken } from 'base/utils/tokenHelper';
import { DeleteModal } from 'components/shared/DeleteModal';
import { observer } from 'mobx-react-lite';
import { useQueryClient } from 'react-query';

import { AxiosResponse } from 'axios';
import { dateTypes } from 'base/models/DashboardProps';
import DeleteDraftResponse from 'base/models/DeleteResponse';
import { DateFormat } from 'components/Dashboard/DateFormat';
import DisplayDSModal from '../DigitalScreenerComponent/Modal/DisplayDSModal.component';
import { Progress } from '../Progress';

interface AssetListFormatProps {
  row: GridRowData;
}

const AssetList: React.FC<AssetListFormatProps> = ({ row }: AssetListFormatProps) => {
  const classes = useStyles(extraStyleProps);
  const [modalOpen, setModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [page, setPage] = useState(0);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const history = useHistory();
  const { setAssetsInStore } = useRootStoreContext().ingestionStore;
  const { startTabsInEditStore, setIsExternalEditingInStore, setRequestIdInStore } =
    useRootStoreContext().editStore;
  const {
    dashboardStore: {
      getStatusFilterInStore: statusFilter,
      groupType,
      getUserFilterInStore: userFilter,
      setSnackbarSettings,
    },
    assetsStore: { processAssets, fetchGetAssetsGrouped },
  } = useRootStoreContext();
  const { getPreviewMaps } = useRootStoreContext().previewStore;
  const totalAssets = row.totalAssets;
  const rowsPerPage = 10;
  const [previewUrl, setPreviewUrl] = useState('');

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    page: number
  ) => {
    setPage(page);
  };

  const [allowCustomPreview, setAllowCustomPreview] = useState<boolean>(false);

  const [closeDeleteConfirm, setCloseDeleteConfirm] = useState<boolean>(false);

  const [currentAsset, setCurrentAsset] = useState<Asset | null>(null);

  const token = previewToken();

  const templateQueryClient = useQueryClient();

  const [isDSModalOpen, setIsDSModalOpen] = useState(false);

  useEffect(() => {
    if (currentAsset) {
      const base64 = getPreviewMaps.get(currentAsset.assetId);
      if (base64) {
        setPreviewUrl(base64.Base64Small);
        return;
      }
    }
  }, [currentAsset]);

  const handeMenuClick = async (
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    asset: Asset
  ) => {
    // We update here the selected Asset for Edit and Delete
    setCurrentAsset(asset);
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    const status =
      asset.status.id === undefined || asset.status.id === null ? asset.status : asset.status.id;

    const base64 = currentAsset ? getPreviewMaps.get(currentAsset.assetId) : undefined;
    if (base64) {
      setPreviewUrl(base64.Base64Small);
    } else {
      const smallCdnUrl = buildPreviewCdnUrl(
        token as string,
        asset.customPreviewId ?? (asset.previewId as string),
        SIZES.SMALL
      );
      setPreviewUrl(smallCdnUrl);
    }

    if (status !== AssetStatus.Draft) {
      setAllowCustomPreview(true);
    }
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    // We wait for animation to end
    setTimeout(() => {
      setCurrentAsset(null);
      setPreviewUrl('');
    }, 500);
  };

  const fetchTemplate = async (templateId: string) => {
    const queryObject: any = templateQueryClient.getQueryData([QUERY_IDENTIFIERS.ASSET_TEMPLATES]);

    if (queryObject && queryObject.data) {
      const template = getTemplateFromCached(templateId, queryObject.data);
      if (template) return template;
    }
  };

  const handleEditAsset = async () => {
    if (currentAsset) {
      if (
        currentAsset.status.id === AssetStatus.Draft ||
        currentAsset.status.id === AssetStatus.Failed
      ) {
        const assets: Assets = [currentAsset];
        const assetsWithUploadProperties = getAssetsWithUploadProperties(assets);
        if (!hasTitleOrAssetGroup(currentAsset)) setAssetsInStore(assetsWithUploadProperties); // for the unassigned assets

        const assetToRedirect: Asset | undefined = assets.find(
          (asset: Asset) => asset.assetGroup && asset.assetGroup.name
        );

        let template: any;
        if (assets[0] && assets[0].templateId) {
          template = await fetchTemplate(assets[0].templateId);
          startTabsInEditStore(assetsWithUploadProperties, template);
        }
        if (assetToRedirect)
          history.push({
            pathname: `/${ROUTES.EDIT}`,
            state: { assetGroupName: assetToRedirect.assetGroup.name },
          });
        else history.push(`/${ROUTES.EDIT}`);
      } else {
        const { response } = await getDraftsFromAssets([currentAsset.assetId]);
        const payload = response?.data;
        if (payload) {
          const assetsWithUploadProperties = getAssetsWithUploadProperties(payload.assets);
          const assets = assetsWithUploadProperties.map((asset: Asset) => {
            return {
              ...asset,
              status: payload.assets[0].status,
            };
          });
          if (!hasTitleOrAssetGroup(currentAsset)) setAssetsInStore(assets);

          const assetToRedirect: Asset | undefined = payload.assets.find(
            (asset: Asset) => asset.assetGroup && asset.assetGroup.name
          );

          setIsExternalEditingInStore(true);
          setRequestIdInStore(payload.requestId);
          let template: any;
          if (payload.assets[0] && payload.assets[0].templateId) {
            template = await fetchTemplate(payload.assets[0].templateId);
            startTabsInEditStore(assets, template);
          }
          if (assetToRedirect)
            history.push({
              pathname: `/${ROUTES.EDIT}`,
              state: { assetGroupName: assetToRedirect.assetGroup.name },
            });
          else history.push(`/${ROUTES.EDIT}`);
        }
      }
    }
  };

  const handleDelete = async (asset: Asset) => {
    let response = { success: true, refreshNeeded: true };
    const deleteDraftStatusTypes = [AssetStatus.Draft, AssetStatus.Failed, AssetStatus.InProgress];
    if (deleteDraftStatusTypes.includes(asset.status.id)) {
      const draftDeleted = await deleteDraft(asset.id);
      await deleteNotification(draftDeleted, 'DraftAsset');

      const draftDeletedResponse = draftDeleted.response
        .response as AxiosResponse<DeleteDraftResponse>;

      if (draftDeletedResponse?.data) {
        if (draftDeletedResponse.status === 400) {
          response = {
            ...response,
            refreshNeeded:
              response.refreshNeeded && !draftDeletedResponse.data.uploadCheckpoints?.length,
            success: false,
          };
          if (draftDeletedResponse.data.uploadCheckpoints?.length) {
            setCurrentAsset({
              ...asset,
              uploadCheckpoints: draftDeletedResponse.data.uploadCheckpoints,
            });
            const updatedAssetArray = row.assets.map((rowAsset: Asset) =>
              asset.id === currentAsset?.id
                ? {
                    ...rowAsset,
                    uploadCheckpoints: draftDeletedResponse.data.uploadCheckpoints,
                  }
                : rowAsset
            );
            processAssets([updatedAssetArray]);
          }
        }
      }
    } else {
      const assetIds = [asset.assetId];
      const deletedAsset = await postAssetDeleteRequest(assetIds);

      response = { ...response, refreshNeeded: true, success: true };
      await deleteNotification(deletedAsset, 'Asset');
    }
    return response;
  };

  const deleteAsset = async () => {
    setCloseDeleteConfirm(false);
    setMenuOpen(false);
    if (currentAsset) {
      const deleteResponse = await handleDelete(currentAsset);

      if (deleteResponse.success) {
        row = {
          ...row,
          assets: row.assets.filter((asset: Asset) => asset.id !== currentAsset.id),
        };
      }

      if (deleteResponse.refreshNeeded) {
        fetchGetAssetsGrouped(statusFilter, groupType, userFilter.email, true);
        setCurrentAsset(null);
      }
    }
  };

  const deleteNotification = async (data: any, deleteType: string) => {
    if (data && data.response) {
      setSnackbarSettings(
        true,
        data.response.status === 204
          ? deleteType + ' deleted'
          : data.response.response.data.message,
        data.response.status === 204 ? 'success' : 'error'
      );
    }
  };

  const handleDSModal = async () => {
    setIsDSModalOpen(true);
  };

  const [sampleAsset] = row.assets;
  const status = sampleAsset.status.id ?? sampleAsset.status;
  const showProgress = [AssetStatus.Draft, AssetStatus.InProgress];
  const uploadTimeFormat = 'h:mm:ss A on DD/MMM/YYYY';

  return (
    <div className="asset-list-cta">
      <Button className={classes.root} onClick={handleModalOpen}>
        {totalAssets}
      </Button>
      <span className={classes.fakeSpacer}> </span>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableTitle}> Name </TableCell>
                <TableCell className={classes.tableTitle}>
                  <Box display="flex" flexWrap="wrap">
                    <span className={classes.fakeSpacerRight}>Status: </span>
                    <AssetStatusFormat status={status} />
                  </Box>
                </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.assets
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((asset: Asset) => {
                  const assetAddedToDraft = asset.uploadCheckpoints?.find(
                    (f) => f.id === 'asset-added-to-draft'
                  );
                  const assetReadyForWonderland = asset.uploadCheckpoints?.find(
                    (f) => f.id === 'asset-added-to-destination'
                  );

                  return (
                    <TableRow key={asset.id}>
                      <TableCell component="th" scope="row">
                        {asset.fields
                          ? asset.fields.fileName
                          : asset.filename
                          ? asset.filename
                          : ''}
                        <br></br>
                        {asset.packageId ? (
                          <IconButton
                            aria-label="more"
                            aria-haspopup="true"
                            onClick={handleDSModal}
                            className={classes.smallIcon}
                          >
                            <LaunchIcon />
                          </IconButton>
                        ) : null}
                        {isDSModalOpen ? (
                          <DisplayDSModal
                            packageId={asset.packageId || ''}
                            open={isDSModalOpen}
                            setOpen={setIsDSModalOpen}
                          ></DisplayDSModal>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {showProgress && !assetAddedToDraft && <Progress asset={asset} />}

                        {showProgress &&
                          assetAddedToDraft &&
                          !assetReadyForWonderland &&
                          asset.status.id === AssetStatus.InProgress && (
                            <div className={classes.transferText}>Transferring ...</div>
                          )}

                        {assetReadyForWonderland && assetAddedToDraft && (
                          <div className={classes.successText}>Uploaded!</div>
                        )}

                        {assetAddedToDraft && (
                          <div>
                            <em>{`DMDC - `}</em>
                            <DateFormat
                              date={new Date(assetAddedToDraft.value)}
                              format={dateTypes.DATE_TYPE_STANDARD}
                              dateFormat={uploadTimeFormat}
                            />
                          </div>
                        )}
                        {assetReadyForWonderland && (
                          <div>
                            <em>{`Wonderland - `}</em>
                            <DateFormat
                              date={new Date(assetReadyForWonderland.value)}
                              format={dateTypes.DATE_TYPE_STANDARD}
                              dateFormat={uploadTimeFormat}
                            />
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="more"
                          aria-haspopup="true"
                          onClick={(e) => handeMenuClick(e, asset)}
                          className={classes.smallIcon}
                        >
                          <MoreHoriz />
                        </IconButton>
                        <Menu
                          id="asset-menu"
                          anchorEl={anchorEl}
                          open={menuOpen}
                          onClose={handleMenuClose}
                          elevation={1}
                          PaperProps={{
                            style: {
                              width: 200,
                              backgroundColor: '#3E3E3E',
                              color: 'white',
                            },
                          }}
                        >
                          {allowCustomPreview ? (
                            <Badge
                              overlap="rectangle"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              badgeContent={
                                <CustomPreviewMenu
                                  asset={currentAsset}
                                  updateAsset={setCurrentAsset}
                                />
                              }
                            >
                              <Avatar className={`${classes.avatar} dmdc-avatar`} variant="square">
                                <img
                                  src={previewUrl}
                                  className={classes.avatarImg}
                                  onError={({ currentTarget }) => {
                                    setPreviewUrl(PREVIEW_DEFAULT_IMAGE);
                                  }}
                                />
                              </Avatar>
                            </Badge>
                          ) : (
                            <Avatar className={`${classes.avatar} dmdc-avatar`} variant="square">
                              <img
                                src={previewUrl}
                                className={classes.avatarImg}
                                onError={({ currentTarget }) => {
                                  setPreviewUrl(PREVIEW_DEFAULT_IMAGE);
                                }}
                              />
                            </Avatar>
                          )}

                          <div key={asset.id}>
                            <Divider variant="middle" className={classes.divider} />
                            {asset.status.id !== AssetStatus.InProgress && (
                              <MenuItem onClick={() => handleEditAsset()}>
                                <ListItemIcon>
                                  <EditOutlined className={classes.iconMenu} />
                                </ListItemIcon>
                                Edit Asset
                              </MenuItem>
                            )}
                            <MenuItem onClick={() => setCloseDeleteConfirm(true)}>
                              <ListItemIcon>
                                <CancelOutlined className={classes.iconMenu} />
                              </ListItemIcon>
                              Remove Asset
                            </MenuItem>
                          </div>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            className={classes.paginator}
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={row.assets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={() => null}
          />
        </div>
      </Modal>
      <DeleteModal
        open={closeDeleteConfirm}
        setOpen={setCloseDeleteConfirm}
        onConfirm={deleteAsset}
      />
    </div>
  );
};

export default observer(AssetList);
