import React, { useRef } from 'react';
import { Asset } from 'base/models/Asset';
import useAsperaTransfer from 'base/hooks/useAsperaTransfer';

import { AssetProgressContainer, AssetProgressSpan } from './UploadProgress.styles';
import { CircularProgress } from '@material-ui/core';
import { Aspera } from 'base/utils/aspera-client';

interface UploadProgressProps {
  asset: Asset;
  tabName: string;
  titleName: string;
}

const UploadProgress = ({ asset, tabName, titleName }: UploadProgressProps) => {
  const { current: aspera } = useRef(new Aspera());
  aspera.init();
  const isEditGrid = true;
  useAsperaTransfer(
    aspera,
    asset.asperaTransferId ?? '',
    asset.fields.originalFileName ?? '',
    asset.id,
    tabName,
    titleName,
    isEditGrid,
    asset
  );

  return (
    <>
      {!asset.uploadProperties?.isUploaded && (
        <AssetProgressContainer>
          <AssetProgressSpan>
            <strong>{`${Math.floor(asset.uploadProperties?.progress)}%`}</strong>
          </AssetProgressSpan>
          <CircularProgress
            variant="determinate"
            value={Math.floor(asset.uploadProperties?.progress)}
          />
        </AssetProgressContainer>
      )}
    </>
  );
};

export default UploadProgress;
