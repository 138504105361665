import { AuthSettings } from 'base/models/AuthSettings';
import { ColumnTemplate } from 'base/stores/editStore';
import { resolvePromise } from 'base/utils/resolveAPICall';
import api from './config';

export const getAuthSettings = async (applicationId: number) => {
  const response = await resolvePromise(
    api.get<AuthSettings>(`settings?applicationId=${applicationId}`)
  );
  return response;
};

export const postCustomTemplate = async (columnTemplate: ColumnTemplate) => {
  const response = await resolvePromise(api.post<any>('setColumnOrder', columnTemplate));
  return response;
};
