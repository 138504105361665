import api from './config';
import { resolvePromise } from '../utils/resolveAPICall';
import { Asset } from 'base/models/Asset';
import { store } from 'base/stores/rootStore';

export const publishDraftPreview = async (asset: Asset) => {
  const response = await resolvePromise(api.post<Asset>('draft/preview', asset));
  return response;
};

export const getPreviewToken = async (expirationTime: number) => {
  return await resolvePromise(api.get(`sasToken/create?hoursTimeToLive=${expirationTime}`));
};

export const buildPreviewCdnUrl = (token: string, previewId: string, size: string) => {
  const url = process.env.REACT_APP_PREVIEW_URL || store.settingsStore.keys?.PREVIEW_URL;
  return `${url}/${size}/${previewId}?${token}`;
};

export const getCdnUrl = async (cdnUrl: string) => {
  return await resolvePromise(api.get(cdnUrl));
};

export const getPreviewIdFromAppId = async (id: string) => {
  return await resolvePromise(api.get(`external/assets/preview/${id}`));
};

export const getCustomPreviewByAssetId = async (assetId: string) => {
  return await resolvePromise(api.get(`customPreview/${assetId}`));
};

export const putCustomPreviewFile = async (file: FormData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return await resolvePromise(api.post(`custom/preview`, file, config));
};

export const restoreOriginalPreviewAsync = async (asset: Asset) => {
  const response = await resolvePromise(api.post<Asset>('preview/restore-original', asset));
  return response;
};
