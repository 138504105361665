import React, { useState, useEffect } from 'react';
import { PreviewImg, Container, FlexContainer } from './FileInfo.styles';

import { PREVIEW_DEFAULT_IMAGE, SIZES } from 'base/constants/previewFiles';
import { Asset } from 'base/models/Asset';
import { buildPreviewCdnUrl } from 'base/api/preview';

import { useRootStoreContext } from 'base/stores/rootStore';
import Cookies from 'js-cookie';

interface FileInfoObject {
  fileName: string;
  shortId: string;
  size: string;
  lastModified: string;
}

interface FileInfoProps {
  title: string;
  fileInfo: FileInfoObject;
  asset: Asset | null;
  newPreviewURL?: string;
}

const columnNames = ['File Name', 'Media ID', 'Size', 'Last Modified'];

export const FileInfo = ({ title, fileInfo, asset, newPreviewURL }: FileInfoProps) => {
  const { getPreviewMaps } = useRootStoreContext().previewStore;
  const [previewUrl, setPreviewUrl] = useState(PREVIEW_DEFAULT_IMAGE);

  const token = process.env.REACT_APP_PREVIEW_TOKEN ?? Cookies.get('cdnt');

  const setupPreview = async () => {
    if (asset && !newPreviewURL) {
      const base64 = getPreviewMaps.get(asset.assetId);
      if (base64) {
        setPreviewUrl(base64.Base64Small);
        return;
      }

      const smallCdnUrl = buildPreviewCdnUrl(
        token as string,
        asset.customPreviewId ?? (asset.previewId as string),
        SIZES.SMALL
      );
      setPreviewUrl(smallCdnUrl);
    }
  };

  useEffect(() => {
    setupPreview();
  }, [asset]);

  useEffect(() => {
    if (newPreviewURL) setPreviewUrl(newPreviewURL);
  }, [newPreviewURL]);

  return (
    <>
      <Container marginBottom="30px">
        <Container marginBottom="10px">
          <label>{title}</label>
        </Container>
        <FlexContainer flexDirection="row">
          <div>
            {!asset ? (
              <PreviewImg src={PREVIEW_DEFAULT_IMAGE} />
            ) : (
              <PreviewImg
                src={previewUrl}
                onError={(event) => setPreviewUrl(PREVIEW_DEFAULT_IMAGE)}
              />
            )}
          </div>
          <FlexContainer flexDirection="column">
            {Object.values(fileInfo).map((value, index) => (
              <Container marginBottom="7px" key={value}>
                <div style={{ fontSize: '13px' }}>
                  <strong>{columnNames[index]}</strong>
                </div>
                <div style={{ fontSize: '13px' }}>
                  <label>{value}</label>
                </div>
              </Container>
            ))}
          </FlexContainer>
        </FlexContainer>
      </Container>
    </>
  );
};

export default FileInfo;
