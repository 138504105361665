import React, { useEffect, useState } from 'react';
import { useStyles } from './UploadScreeners.style';
import { store, useRootStoreContext } from 'base/stores/rootStore';
import UploadWidget from './UploadWidget/UploadWidget.page';
import localStorageAccess, { LocalStorageKeys } from 'base/utils/localStorageAccess';
import { AuthenticationResponse } from 'base/models/UserInfo';
import { observer } from 'mobx-react-lite';
import { UserActionFlags } from 'base/models/AuthSettings';

const UploadScreeners: React.FC = () => {
  const { getPortalId } = useRootStoreContext().dashboardStore;
  const { userActionFlags } = useRootStoreContext().settingsStore;

  const classes = useStyles();
  const [errorWidgetMessage, setWidgetErrorMessage] = useState('');
  const [isToken, setIsToken] = useState(Boolean);
  const [isConfigID, setConfigID] = useState(Boolean);

  const [LocalPortalId, setDsPortalId] = useState(
    process.env.REACT_APP_DSPORTAL_ID?.toString() || store.settingsStore.keys?.DSPORTAL_ID || ''
  );

  const [widgetUserActions, setUserActionsForWidget] = useState<string>('');
  const token =
    localStorageAccess.getFromLocalStorage<AuthenticationResponse>(LocalStorageKeys.userInfo)
      ?.token || '';

  const styleUrl =
    process.env.REACT_APP_DSUPLOADWIDGET_STYLE?.toString() ||
    store.settingsStore.keys?.DSUPLOADWIDGET_STYLE;
  const scriptUrl =
    process.env.REACT_APP_DSUPLOADWIDGET_SCRIPT?.toString() ||
    store.settingsStore.keys?.DSUPLOADWIDGET_SCRIPT;

  function callDSUploadWidget() {
    if (styleUrl && scriptUrl) {
      const linkElement = document.createElement('link');
      linkElement.setAttribute('rel', 'stylesheet');
      linkElement.setAttribute('href', styleUrl);
      document.head.appendChild(linkElement);

      const scriptElement = document.createElement('script');
      scriptElement.setAttribute('type', 'module');
      scriptElement.setAttribute('id', 'widget-lib');
      scriptElement.setAttribute('src', scriptUrl);
      document.head.appendChild(scriptElement);
    } else {
      setWidgetErrorMessage('Digital Screeners Widget could not be loaded');
    }
  }

  const handleOnClick = async () => {
    if (getPortalId !== '' && token !== '') {
      setConfigID(true);
      setIsToken(true);
    } else {
      setConfigID(false);
      setWidgetErrorMessage('Unable to retrieve Widget settings');
    }
  };

  const buildWidgetUserActions = () => {
    let actionFlags: UserActionFlags = {
      canUploadMediaFilesForPackaging: false,
      canViewUploadStatus: false,
    };

    if (userActionFlags) {
      actionFlags = { ...userActionFlags };
    }

    const actionFlagsText = JSON.stringify(actionFlags);

    return actionFlagsText;
  };

  const resetUploadWidget = () => {
    setConfigID(false);
    setDsPortalId(getPortalId);
    setUserActionsForWidget(buildWidgetUserActions());
  };

  useEffect(() => {
    resetUploadWidget();
  }, [getPortalId, userActionFlags]);

  useEffect(() => {
    setDsPortalId(getPortalId);
    callDSUploadWidget();
    handleOnClick();
  }, [LocalPortalId, widgetUserActions]);

  return (
    <div>
      {isToken && isConfigID ? (
        <UploadWidget
          token={token}
          dsPortalID={getPortalId}
          userActionFlags={userActionFlags}
          widgetUserActions={widgetUserActions}
        />
      ) : (
        <label className={classes.errorLabelStyle}>{errorWidgetMessage}</label>
      )}
    </div>
  );
};

export default observer(UploadScreeners);
