import React from 'react';
import { assetStatusText } from 'base/models/DashboardProps';
import { statusColors } from 'styles/colors';
import { AssetStatus } from 'base/enums/AssetStatus';

interface AssetStatusFormatProps {
  status: AssetStatus;
}

const AssetStatusFormat: React.FC<AssetStatusFormatProps> = ({
  status,
}: AssetStatusFormatProps) => {
  let textColor = '#00000';
  let textStatus = 'Status Invalid';

  switch (status) {
    case AssetStatus.Draft:
      textColor = statusColors.DRAFT;
      textStatus = assetStatusText.DRAFT;
      break;
    case AssetStatus.InProgress:
      textColor = statusColors.IN_PROGRESS;
      textStatus = assetStatusText.IN_PROGRESS;
      break;
    case AssetStatus.NotPublished:
      textColor = statusColors.NOT_PUBLISHED;
      textStatus = assetStatusText.NOT_PUBLISHED;
      break;
    case AssetStatus.Published:
      textColor = statusColors.PUBLISHED;
      textStatus = assetStatusText.PUBLISHED;
      break;
    case AssetStatus.Holdback:
      textColor = statusColors.HOLDBACK;
      textStatus = assetStatusText.HOLDBACK;
      break;
    case AssetStatus.Failed:
      textColor = statusColors.FAILED;
      textStatus = assetStatusText.FAILED;
      break;
  }

  return <div style={{ color: textColor }}>{textStatus}</div>;
};

export default AssetStatusFormat;
